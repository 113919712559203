export const separateWords = (text) => {
    const words = text.split(/\s+/);
    const wordElements = words.map((word, index) => {
        const delay = (index + 1) * 0.2; 
        const spanStyle = {
            animationDelay: `${delay}s`,
        };
        return <span key={index} style={spanStyle}>
                {word}&nbsp;
            </span>
    });
    return wordElements;
}