import React, { useEffect, useState } from 'react';
import { ReactComponent as TheGuyLogo } from '../assets/imgs/logo_TheGuy.svg';
import {
	Hamburger,
	HeaderContainer,
	MobileMenuContainer,
	NavItem,
	NavWrapper,
} from '../Styles/HeaderStyles';
import ThemeSwitcher from './ThemeSwitcher';
import Sidebar from './Sidebar';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

function MobileHeader({isDarkTheme}) {
	const [isMobileOpen, setIsMobileOpen] = useState(false);
	const [lastScrollPosition, setLastScrollPosition] = useState(0);
	const [scrollDir, setScrollDir] = useState('none');

	const headerClassListHandler = () => {
		const scrollTop =
			window.pageYOffset || document.documentElement.scrollTop;
		setLastScrollPosition(scrollTop);
		if (scrollTop === 0) {
			setScrollDir('none');
		} else if (
			scrollTop > lastScrollPosition &&
			scrollTop > 50 &&
			scrollDir !== 'down'
		) {
			setIsMobileOpen(false);
			setScrollDir('down');
		} else if (scrollTop <= lastScrollPosition && scrollDir !== 'up') {
			setScrollDir('up');
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', headerClassListHandler);
		return () => {
			window.removeEventListener('scroll', headerClassListHandler);
		};
	}, [lastScrollPosition]);

	return (
		<>
			<HeaderContainer
				className={`${isMobileOpen && 'menu-open'} ${
					scrollDir === 'down'
						? 'scroll-to-bottom'
						: scrollDir === 'up'
						? 'scroll-to-top'
						: ''
				}`}
			>
				<NavWrapper className={'flex justify-between align-center'}>
					<NavItem>
						<ThemeSwitcher />
					</NavItem>
					<NavItem className={'absolute-center'}>
						<Link to={'/'}>
							<TheGuyLogo className={isDarkTheme ? 'dark' : 'light'}/>
						</Link>
					</NavItem>
					<NavItem>
						<Hamburger
							className={isMobileOpen && 'open'}
							onClick={() => {
								setIsMobileOpen(!isMobileOpen);
							}}
						>
							<div className='strip burger-strip'>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</Hamburger>
					</NavItem>
				</NavWrapper>
			</HeaderContainer>

			<MobileMenuContainer className={isMobileOpen && 'open'}>
				<Sidebar className={'mobile-menu-wrapper'} setIsMobileOpen={setIsMobileOpen} isMobileOpen={isMobileOpen}/>
			</MobileMenuContainer>
		</>
	);
}

export default MobileHeader;
