import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { SidebarStyle } from '../Styles/GeneralStyles';
import { useContext } from 'react';
import AppContext from '../Contexts/AppContext';
import { userLogout } from '../services/api';
import { useNavigate } from 'react-router-dom';

function Sidebar({ className, setIsMobileOpen, isMobileOpen}) {
	let navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState('contact us');
	const pages = ['about', 'projects', 'contact us'];
	const { loggedInUser, screenSize, setLoggedInUser } =
		useContext(AppContext);

	const scrollToTarget = () => {
		setTimeout(() => {
			this.targetRef.scrollIntoView({ behavior: 'smooth' });
		}, 500);
	};

	const handleLogout = async () => {
		const res = await userLogout();
		if (res.success) {
			setLoggedInUser({});
			navigate('/login');
		}
	};

	return (
		<SidebarStyle className={className}>
			{pages.map((page, index) => (
				<>
					{page === 'contact us' ? (
						<HashLink
							key={index}
							className={page === currentPage ? 'bold active' : 'bold'}
							to='#form'
							smooth={true}
							onClick={(e) => {
								isMobileOpen && setIsMobileOpen(false);

							}}
					  	>
						{page}
					  </HashLink>
					) : (
						<Link
							className={page === currentPage ? 'active' : ''}
							key={index}
							onClick={() => {
								setIsMobileOpen(false);
								setCurrentPage(page);
							}}
							to={`/${page}`}
							replace={true}
						>
							{page}
						</Link>
					)}
				</>
			))}
			{Object.keys(loggedInUser).length > 0 &&
				screenSize.current <= 768 && (
					<Link
						onClick={() => handleLogout()}
						style={{ position: 'absolute', bottom: '5%' }}
						primary
					>
						LOGOUT
					</Link>
				)}
			{/* <Link to={'/about'}>ABOUT</Link>
			<Link to={'/projects'}>PROJECTS</Link>
			<Link to={'/'} className={'bold active'}>
				CONTACT US
			</Link> */}
		</SidebarStyle>
	);
}

export default Sidebar;
