import React from 'react'
import { LoaderContainer, LoaderItem } from '../../Styles/Admin Panel Styles/LoaderStyles'
import {ReactComponent as TheGuyLogo} from '../../assets/imgs/theguy_el.svg';

function Loader() {
  return (
      <LoaderContainer className='flex align-center justify-center'>
        <TheGuyLogo className={'loader-animated'}/>
      </LoaderContainer> 
  )
}

export default Loader