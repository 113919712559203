import React, { useContext, useEffect, useState } from 'react'
import { CreateSubField, RemoveSubField, UpdateSubfield, UploadFile } from '../../services/api'
import { Input } from '../../Styles/Admin Panel Styles/InputStyles'
import { InputWrapper } from '../../Styles/Admin Panel Styles/InputStyles';
import { ContainedButton, EditBtn } from '../../Styles/Admin Panel Styles/ButtonStyles';
import { NumberStyle } from '../../Styles/GeneralStyles';
import AppContext from '../../Contexts/AppContext';
import { GridItem, ImagesContainerLg } from '../../Styles/Admin Panel Styles/Containers';
import { ReactComponent as DeleteIcon } from '../../assets/imgs/Admin Panel/delete_forever_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as UploadIcon } from '../../assets/imgs/Admin Panel/upload_FILL0_wght400_GRAD0_opsz24.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SERVER_ENDPOINT } from '../../services';
import { darkTheme } from '../../Styles/CustomTheme';

function TeamEdit({field, handleOpenModal, page_id}) {
  
  const {handleGetFields} = useContext(AppContext);
  const [childFields, setChildFileds] = useState(field?.children ? field.children : []);
  const [fieldsToDelete, setFieldsToDelete] = useState([]);

  const handleOnSubfieldChange = (id, value, key) => {
    const tmp_fields = [...childFields];
    const field_to_change = tmp_fields.find(field=> field.id == id);
    // console.log(field_to_change);
    field_to_change.subfield_content[`${key}`] = value;
    setChildFileds(tmp_fields);
  }

  console.log(childFields)

  const handleUpdateSubfields = async() => {
    if(fieldsToDelete.length){
      for (let i = 0; i < fieldsToDelete.length; i++) {
        const deleted_field = await RemoveSubField(fieldsToDelete[i]);
      }
    }

    const fields_to_update = childFields.filter(field=> typeof field.id === 'number');
    const new_fields = childFields.filter(field=> typeof field.id === 'string' && field.id.includes('temp'));
    
    for (let i = 0; i < fields_to_update.length; i++) {
      const el = fields_to_update[i];
      let upload_front_img;
      let upload_back_img;

      if(el?.subfield_content?.front_img?.file){
        const res = await UploadFile(el.subfield_content.front_img.file);
        if(res.success && res.data) upload_front_img = res.data;
      }
      if(el?.subfield_content?.back_img?.file){
        const res = await UploadFile(el.subfield_content.back_img.file);
        if(res.success && res.data) upload_back_img = res.data;
      }

      let obj_to_update = {
        ...el,
      }

      if(upload_front_img) obj_to_update.subfield_content.front_img = upload_front_img;
      if(upload_back_img) obj_to_update.subfield_content.back_img = upload_back_img;
      
      await UpdateSubfield(obj_to_update)
    }

    for (let i = 0; i < new_fields.length; i++) {
      const el = new_fields[i];
      let upload_front_img;
      let upload_back_img;
      if(el?.subfield_content?.front_img?.file){
        const res = await UploadFile(el.subfield_content.front_img.file);
        if(res.success && res.data) upload_front_img = res.data;
      }
      if(el?.subfield_content?.back_img?.file){
        const res = await UploadFile(el.subfield_content.back_img.file);
        if(res.success && res.data) upload_back_img = res.data;
      }

      let tmp_obj = {
        ...el,
      }

      if(upload_front_img) tmp_obj.subfield_content.front_img = upload_front_img;
      if(upload_back_img) tmp_obj.subfield_content.back_img = upload_back_img;
      
      await CreateSubField(tmp_obj)
    }

    handleGetFields(page_id);
    handleOpenModal();
  }

  const handleSetImage = (event, key) => {
    let tmp_team_imgs = [...childFields];
    let founded_obj = tmp_team_imgs.find(img=> img.id == event.target.id);
    if(founded_obj){
        // console.log(founded_obj, key);
        founded_obj.subfield_content[`${key}`] ? 
        founded_obj.subfield_content[`${key}`].file = event.target.files[0] : 
        founded_obj.subfield_content[`${key}`] = {
            file: event.target.files[0]
        }
        const reader = new FileReader();
        reader.onloadend = function() {
            let tmp_fields = [...childFields];
            let founded_item = tmp_fields.find(item=> item.id == event.target.id);
            if(founded_item){
                founded_item.subfield_content[`${key}`].file_url = reader.result;
            }
            setChildFileds(tmp_fields);
        };
        reader.readAsDataURL(event.target.files[0]);
    } 
  };

  const handleRemoveImg = (subfield_id, img_type) => {
    const tmp_child_fields = [...childFields];
    const founded_sub = tmp_child_fields.find(child=>child.id === subfield_id);
    if(founded_sub){
      founded_sub.subfield_content[`${img_type}`] = null;
      setChildFileds(tmp_child_fields);
    }
  }

  const handleDeleteSubField = (id) => {
    const tmp_child_fields = [...childFields];
    const index = tmp_child_fields.findIndex(item => item.id === id); // find the index of the object with the given id
    if (index !== -1) {
      tmp_child_fields.splice(index, 1); // remove the object from array
    }

    // console.log(tmp_child_fields);
    setChildFileds(tmp_child_fields);

    //saving into array of obj's to delete
    if(fieldsToDelete.includes(id)) return;
    setFieldsToDelete([...fieldsToDelete, id]);
  }

  const addNewChildField = () => {
    setChildFileds([...childFields, {
      id: 'temp' + childFields.length, //temp id
      field_id: field.id,
      subfield_content: {name: '', role: '', front_img: null, back_img: null, order: childFields.length-1},
      subfield_name: "team_block",
      subfield_type: "text",
    }])
  }

  useEffect(() => {
    setChildFileds(field?.children?.sort((a, b) => {
      return a.subfield_content.order - b.subfield_content.order;
    }));
  }, [field])

  return (

    <div className='flex-column' style={{gap: '3vw'}}>
          {childFields?.length > 0 && childFields.map((child_field, i)=>(
            <div className='flex align-center justify-between'>
              <div className="flex-column" style={{gap: 'max(16px, 0.8vw)'}}>
                <NumberStyle
                  className='number-wrapper'
                >
                  <span>{`${i>8 ? i+1 : "0"+(i+1) }`}</span>
                </NumberStyle>
                <EditBtn onClick={()=>handleDeleteSubField(child_field.id)} relative>
                  <DeleteIcon style={{fill: darkTheme.primary}}/> 
                </EditBtn>
              </div>
              <div className='flex-column' style={{gap: 'max(16px, 0.8vw)', width: '85%'}}>
                  <InputWrapper className='flex-column' style={{gap: '0.5vw'}}>
                      <h4>Order:</h4>
                      <Input 
                        value={child_field?.subfield_content?.order} 
                        id={child_field.id}
                        onChange={(e)=>{
                            handleOnSubfieldChange(e.target.id, e.target.value, 'order');
                        }}
                      />
                  </InputWrapper>
                  <InputWrapper className='flex-column' style={{gap: '0.5vw'}}>
                      <h4>Name:</h4>
                      <Input 
                        value={child_field?.subfield_content?.name} 
                        id={child_field.id}
                        onChange={(e)=>{
                            handleOnSubfieldChange(e.target.id, e.target.value, 'name');
                        }}
                      />
                  </InputWrapper>
                  <InputWrapper className='flex-column' style={{gap: '0.5vw'}}>
                      <h4>Role:</h4>
                      <Input 
                      value={child_field.subfield_content.role} 
                      id={child_field.id}
                      onChange={(e)=>{
                          handleOnSubfieldChange(e.target.id, e.target.value, 'role');
                      }}
                      />
                  </InputWrapper>
                  <ImagesContainerLg className='flex justify-between' style={{width: '100%', marginBottom: '2vw'}}>
                      <GridItem className="grid-item relative" style={{width: '48%', height: '280px'}}>
                          <div className="img-wrapper">
                              {child_field.subfield_content.front_img && Object.keys(child_field.subfield_content.front_img)?.length > 0 && 
                                  <LazyLoadImage
                                    src={`${
                                        child_field?.subfield_content?.front_img?.file_url?.includes('base64') ? 
                                        child_field?.subfield_content?.front_img?.file_url : 
                                        SERVER_ENDPOINT + child_field?.subfield_content?.front_img?.file_url
                                    }`} 
                                    alt="the guy" 
                                  />
                              }
                          </div>
                          <div className="btn-wrapper">
                              {child_field.subfield_content.front_img &&  Object.keys(child_field?.subfield_content?.front_img)?.length > 0 ?
                                  <EditBtn
                                      onClick={()=>handleRemoveImg(child_field.id, 'front_img')}
                                      className='edit-proj' 
                                      relative 
                                      light 
                                      fullwidth
                                  > 
                                      <DeleteIcon/> 
                                      REMOVE
                                  </EditBtn>
                                  :
                                  <EditBtn
                                      className='edit-proj' 
                                      relative 
                                      light 
                                      fullwidth
                                  > 
                                      <UploadIcon/> 
                                      UPLOAD FRONT IMAGE
                                      <Input type='file' id={child_field.id} onChange={(e)=>handleSetImage(e, 'front_img')} accept="image/*" className='upload-file-input'/>
                                  </EditBtn>
                              }
                          </div>
                      </GridItem>
                      <GridItem className="grid-item relative" style={{width: '48%', height: '280px'}}>
                          <div className="img-wrapper">
                              {child_field?.subfield_content?.back_img && Object.keys(child_field?.subfield_content?.back_img)?.length > 0 && 
                                  <LazyLoadImage
                                    src={`${
                                        child_field?.subfield_content?.back_img?.file_url.includes('base64') ? 
                                        child_field?.subfield_content?.back_img?.file_url : 
                                        SERVER_ENDPOINT + child_field?.subfield_content?.back_img?.file_url
                                    }`} 
                                    alt="the guy" 
                                  />
                              }
                          </div>
                          <div className="btn-wrapper">
                              {child_field?.subfield_content?.back_img && Object.keys(child_field?.subfield_content?.back_img).length > 0 ?
                                  <EditBtn
                                  onClick={()=>handleRemoveImg(child_field.id, 'back_img')}
                                      className='edit-proj' 
                                      relative 
                                      light 
                                      fullwidth
                                  > 
                                      <DeleteIcon/> 
                                      REMOVE
                                  </EditBtn>
                                  :
                                  <EditBtn
                                      className='edit-proj' 
                                      relative 
                                      light 
                                      fullwidth
                                  > 
                                      <UploadIcon/> 
                                      UPLOAD BACK IMAGE
                                      <Input type='file' id={child_field.id} onChange={(e)=>handleSetImage(e, 'back_img')} accept="image/*" className='upload-file-input'/>
                                  </EditBtn>
                              }
                          </div>
                      </GridItem>
                  </ImagesContainerLg>
              </div>
            </div>
          ))}
        <div className="add-new flex justify-center">
          <EditBtn onClick={addNewChildField} className={`child-field-popup`}>
              ADD NEW +
          </EditBtn>
        </div>
        <div className='btns flex justify-center align-center'>
            <ContainedButton primary onClick={handleUpdateSubfields}> SAVE </ContainedButton>
            <ContainedButton secondary onClick={handleOpenModal}> CANCEL </ContainedButton>
        </div>
    </div>
  )
}

export default TeamEdit