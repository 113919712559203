
import styled, { css } from 'styled-components';

export const NavbarContainer = styled.div`
    position: fixed;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 999;
    padding: 0.3vh 2.8vw;
    background: ${({ theme }) => theme.gray[500]};
    box-shadow:  5.1px 5.1px 10.2px rgba(0, 0, 0, 0.1),
        5.1px 5.1px 10.2px ${({ theme }) => theme.shadow[850]},
        inset 0px 4.08px 42.84px ${({ theme }) => theme.shadow[800]};
    border-radius: 0 0 16px 16px;
    opacity: 1;
    &.scrolled{
        opacity: 0.7;
        // top: 1%;
        // border-radius: 16px;

        &:hover{
            opacity: 1;
        }
    }
`;