import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
	max-width: min(90%, 1233px);
	margin-inline: auto;

	${(props) =>
		props.footer &&
		css`
			max-width: min(90%, 1544px);
		`};

	.edit-btns {
		gap: 16px;
		width: 100%;
		margin-block: 8vh;

		button.edit-proj {
			position: relative;
			bottom: auto;
			left: auto;
			transform: unset;
			// top: min(-50px, -4vw);
		}

		button.child-field-popup {
			position: relative;
			bottom: auto;
			left: auto;
			transform: unset;
			top: auto;
		}
	}

	.related-projects {
		h3.title {
			margin-block: 30px min(80px, 9vw);
			width: fit-content;
		}

		.wrapper {
			margin-bottom: min(112px, 11vw);

			.related-project {
				width: 30%;
				height: max(18.75vw, 250px);
			}
		}

		.related-modal {
			h3.title {
				margin-block: 0;
				text-align: center;
				width: 100%;
			}

			h6.sub {
				padding: 0 8px 8px;
			}
		}
	}
`;

export const SidebarStyle = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	transform: rotate(-90deg);
	right: -269px;
	top: 50%;
	z-index: 9;

	&::before,
	&::after {
		content: '';
		position: static;
		height: 0px;
		width: max(50px, 6.563vw);
		border-bottom: 1px ${({ theme }) => theme.primary_darker} dashed;
	}

	&::before {
		margin-right: 22px;
	}
	&::after {
		margin-left: 22px;
	}

	@media only screen and (max-width: 1280px) {
		right: -240px;
	}

	@media only screen and (max-width: 768px) {
		position: static;
		flex-direction: column;
		transform: unset;

		&::after,
		&::before {
			all: unset;
		}
	}

	span,
	a {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.5;
		color: ${({ theme }) => theme.gray[300]};
		text-decoration: unset;
		text-transform: uppercase;
		transition: all 0.3s ease;

		&.bold {
			font-weight: 700;
			color: ${({ theme }) => theme.gray[50]};
		}
		&.active {
			color: ${({ theme }) => theme.primary};
			text-shadow: -1px -1px 30px rgba(161, 200, 58, 0.51);
		}

		&:nth-child(2) {
			margin-inline: 50px;
		}

		&:hover {
			color: ${({ theme }) => theme.primary};
		}
	}
`;

export const ThemeSwitcherStyle = styled.div`
	position: sticky;
	top: 80%;
	left: -29px;
	transform: rotate(-90deg);
	z-index: 7;
	width: 166px;
	height: 48px;
	display: flex;
	align-items: center;
	/* column-gap: 10px; */
	background: ${({ theme }) => theme.gray[700]};
	box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[300]},
		-1px -1px 2px ${({ theme }) => theme.shadow[600]},
		inset -3px 3px 6px ${({ theme }) => theme.shadow[500]},
		inset 3px -3px 6px ${({ theme }) => theme.shadow[500]},
		inset -3px -3px 6px ${({ theme }) => theme.shadow[400]},
		inset 3px 3px 8px ${({ theme }) => theme.shadow[700]};
	border-radius: 100px;
	/* width: fit-content; */
	padding: 8px 20px;
	cursor: pointer;
	transition: all 0.5s ease;

	&:hover {
		svg {
			transform: rotate(90deg);
		}
	}

	@media only screen and (max-width: 1400px) {
		width: 70px;
		height: 34px;
		padding: 5px;
		// transform: unset;
		left: 100%;
		top: 4%;
		margin-inline-end: -10px;
	}
	

	@media only screen and (max-width: 768px) {
		position: relative;
		bottom: auto;
		left: auto;
		transform: unset;
	}

	span {
		font-family: 'Inter';
		font-weight: 700;
		font-size: 16px;
		line-height: 1.1875;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		color: ${({ theme }) => theme.gray[100]};

		&.dark-title {
			opacity: 0;
		}
	}

	input {
		visibility: hidden;
		opacity: 0;
		position: absolute;

		&:checked {
			+ .light-title {
				opacity: 0;
			}
			+ .light-title + .dark-title {
				opacity: 1;
			}
			+ .light-title + .dark-title + .sun-wrapper {
				right: 74%;
			}
		}

		@media only screen and (max-width: 768px) {
			&:checked {
				+ .sun-wrapper {
					right: 40px;
				}
			}
		}
	}

	.sun-wrapper {
		box-shadow: -5px -5px 10px ${({ theme }) => theme.shadow[200]},
			5px 5px 10px ${({ theme }) => theme.shadow[900]},
			inset 0px 4px 42px ${({ theme }) => theme.shadow[800]};
		border-radius: 40px;
		background: ${({ theme }) => theme.gray[800]};
		transition: all 0.5s ease;
		position: absolute;
		right: 9%;
		top: 50%;
		transform: translateY(-50%);

		svg {
			padding: 6px;
		}

		@media only screen and (max-width: 768px) {
			width: 26px;
			height: 26px;
			right: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: ${({ theme }) => theme.gray[700]};
			box-shadow: -1px -1px 2px ${({ theme }) => theme.shadow[200]},
				1px 1px 2px ${({ theme }) => theme.shadow[900]},
				inset 0px 2px 12px ${({ theme }) => theme.shadow[800]};

			svg {
				padding: 2px;
				width: 24px;
				height: 24px;
			}
		}
	}
`;

export const FlexBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	${(props) =>
		props.padding_top &&
		css`
			padding-top: ${(props) => props.padding_top};
		`};
	${(props) =>
		props.margin_bottom &&
		css`
			margin-bottom: ${(props) => props.margin_bottom};
		`}
	${(props) =>
		props.align_start &&
		css`
			align-items: ${(props) => props.align_start};
		`}
	${(props) =>
		props.text_center &&
		css`
			text-align: ${(props) => props.text_center};
		`}

	&.hero {
		@media only screen and (max-width: 768px) {
			min-height: 100vh;
			padding: 0;
			margin-bottom: 0;
		}

		&.accessibility{
			align-items: start;

			h1{
				margin-bottom: max(40px, 3vw);
			}

			p, li, a{
				font-size: max(18px, 1vw);
				font-weight: 300;
			}

			p, ul{
				margin-bottom: max(24px, 1.5vw);
			}

			ul{
				list-style-type: disc;

				li{
					color: ${({ theme }) => theme.gray[300]};
				}
			}

			a{
				color: inherit;
				text-decoration: underline;

				&:hover{
					color: ${({ theme }) => theme.primary_darker};
				}
			}
		}
	}

	&.clients{
		margin-inline: min(-4%, -343.5px);

		@media only screen and (max-width: 1200px) {
			margin-inline: max(-5%, -343.5px);
		}
	}

	&.projects{
		@media only screen and (max-width: 768px) {
			min-height: 80vh;
			padding: 0;
			margin-bottom: 0;
		}
	}

	h1{
		&.hero-title-home{
			transform: scale(0.94);
  			animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
			
			span {
				display: inline-block;
				opacity: 0;
				filter: blur(4px);
				-webkit-filter: blur(4px);
				animation-name: fade-in;
				animation-duration: 0.8s;
				animation-fill-mode: forwards;
				animation-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
				background: 
					radial-gradient(circle farthest-corner at center center, 
						${({ theme }) => theme.gray[100]} 0%, 
						${({ theme }) => theme.gray[300]} 80%, 
						${({ theme }) => theme.gray[100]} 100%
				);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-shadow: 0px 10px 10px #00000033;
			}

			&.primary{
				span{
					background: ${({ theme }) => theme.primary};
					-webkit-background-clip: text;
				}
			}
		}

		&.gradient{
			background: linear-gradient(to right, ${({ theme }) => theme.primary}, ${({ theme }) => theme.primary}, ${({ theme }) => theme.primary});
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			text-shadow: 
				4px 4px 4px  ${({ theme }) => theme.isDark ? `rgba(0, 0, 0, 0.1)` : `rgba(80, 80, 80, 0.1)`}, 
				8px 8px 8px ${({ theme }) => theme.isDark ? `rgba(0, 0, 0, 0.2)` : `rgba(80, 80, 80, 0.2)`}, 
				10px 10px 10px ${({ theme }) => theme.isDark ? `rgba(0, 0, 0, 0.08)` : `rgba(80, 80, 80, 0.08)`}
		}
	}

	h3{
		width: fit-content;
		font-size: clamp(18px, 2.2vw, 28px);
		// background: ${({ theme }) => theme.isDark && theme.gray[700]};
	}

	h5 {
		margin-top: max(1.354vw, 10px);
		font-size: 14px;
		line-height: 1.5;
		letter-spacing: 0.05em;
	}

	.clients-wrapper {
		width: 100%;
		z-index: 8;
	}
`;

export const ProjectsStyle = styled.div`
	.projects-link {
		font-weight: 700;
		font-size: 16px;
		line-height: 1.2;
		color: ${({ theme }) => theme.primary};
		gap: 8px;

		@media only screen and (max-width: 768px) {
			margin-top: 16px;
		}

		.arrows{
			left: 0;

			svg{
				fill: ${({ theme }) => theme.primary};
			}
		}

		&:hover{
			.arrows{
				left: 7px;
			}
		}
	}

	${(props) =>
		props.margin_bottom &&
		css`
			margin-bottom: ${(props) => props.margin_bottom};
		`}
	${(props) =>
		props.flex &&
		css`
			display: ${(props) => props.flex};
		`}
	${(props) =>
		props.gap &&
		css`
			column-gap: ${(props) => props.gap};
		`}

	.project-wrapper {
		display: flex;

		.project-content {
			margin-top: min(108px, 12vw);
			// z-index: 1;
			// background: ${({ theme }) => theme.gray[700]};
			width: 100%;

			.project-details {
				column-gap: min(24px, 4vw);
				margin-bottom: min(82px, 10vw);

				@media only screen and (max-width: 768px) {
					margin-bottom: unset;
				}

				.project-name {
					position: relative;
					// background: ${({ theme }) => theme.gray[700]};

					.title {
						column-gap: 8px;

						img {
							width: 28px;
							border-radius: 50%;
							object-fit: contain;
							object-position: center;
							aspect-ratio: 1;
						}
					}

					.main-title {
						font-weight: 400;
						font-size: clamp(22px, 3vw, 39px);
						line-height: 1.2;
						color: ${({ theme }) => theme.gray[50]};
						max-width: calc(100% - 28px - 8px);
					}

					.sub-title {
						font-family: 'Inter';
						font-weight: 700;
						font-size: 16px;
						line-height: 1.0625;
						color: ${({ theme }) => theme.gray[200]};
					}
				}
			}
		}
		> a {
			/* flex-grow: 1; */
		}
	}

	&.about {
		@media only screen and (max-width: 768px) {
			flex-direction: column;
			row-gap: 5vw;
		}

		.about-content {
			width: 45%;

			@media only screen and (max-width: 768px) {
				width: 100%;
			}

			.head-title {
				font-family: 'Poppins';
				font-weight: 700;
				font-size: clamp(16px, 1.8vw, 20px);
				line-height: 1.2;
				color: ${({ theme }) => theme.gray[100]};
				margin-bottom: min(10px, 2vw);
			}
			.title {
				margin-bottom: min(30px, 4vw);
			}

			.about-text {
				padding-inline-start: min(175px, 10vw);
				font-family: 'Poppins';
				font-weight: 400;
				font-size: 16px;
				line-height: 1.5625;
				letter-spacing: 0.05em;
				margin-inline-start: auto;

				@media only screen and (max-width: 768px) {
					padding-inline-start: unset;
				}
			}
		}

		.about-image {
			width: 42.6%;
			
			@media only screen and (max-width: 768px) {
				width: 100%;
			}

			img {
				box-shadow: -5.1px -5.1px 10.2px ${({ theme }) => theme.shadow[100]},
				5.1px 5.1px 14px ${({ theme }) => theme.shadow[850]},
				inset 0px 4.08px 42.84px ${({ theme }) => theme.shadow[800]};
				border-radius: min(25px, 2vw);
					@media only screen and (max-width: 768px) {
						margin-inline: auto;
					}
				}
		}
	}
`;

export const ProjectsContainer = styled.div`
	@media only screen and (max-width: 768px) {
		gap: 1vmin;
	}
`;

export const ServiceBlocksContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);

	@media only screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		margin-top: 8vmin;
		padding-inline: 30px;
	}
`;

export const LogoStyle = styled.div`
	position: fixed;
	top: 5%;
	left: 5%;
	transition: all 0.7s ease;

	&.home_page {
		left: 50%;
		transform: translateX(-50%);
	}

	&.scroll {
		left: 5%;
		transform: translateX(0);
		// width: clamp(100px, 14vw, 140px);
	}

	svg {
		transition: all 0.7s ease;
		* {
			transition: all 0.7s ease;
		}
		.hidden {
			fill: transparent;
		}

		.show {
			fill: ${({ theme }) => theme.gray[100]};
		}
	}

	&:hover {
		svg {
			path {
				fill: rgba(162, 200, 58, 0.5);
				&.hidden {
					fill: transparent;
				}
			}
		}
	}
`;

export const NumberStyle = styled.div`
	background: ${({ theme }) => theme.gray[700]};
	box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[300]},
		-1px -1px 2px ${({ theme }) => theme.shadow[600]},
		inset -3px 3px 6px ${({ theme }) => theme.shadow[500]},
		inset -2px -2px 5px ${({ theme }) => theme.shadow[400]},
		inset 3px 3px 10px ${({ theme }) => theme.shadow[700]};
	border-radius: 50%;
	width: fit-content;

	${(props) =>
		props.absolute &&
		css`
			position: absolute;
		`}

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		aspect-ratio: 1;
		color: ${({ theme }) => theme.primary};
		font-weight: 700;
		font-size: clamp(16px, 1.8vw, 20px);
	}
`;

export const CardStyle = styled.div`

	position: relative;
	background: ${({ theme }) => theme.gradient[400]};
	box-shadow: -5.1px -5.1px 10.2px ${({ theme }) => theme.shadow[100]},
		5.1px 5.1px 14px ${({ theme }) => theme.shadow[850]},
		inset 0px 4.08px 42.84px ${({ theme }) => theme.shadow[800]};
	border-radius: min(25px, 2vw);
	height: 100%;
	overflow: hidden;

	@media only screen and (max-width: 768px) {
		max-height: unset;
	}

	.image-wrapper {
		position: relative;
		height: 100%;

		img {
			border-radius: min(25px, 2vw);
			width: 100%;
			object-fit: cover;
			height: 100%;

			&:nth-child(2) {
				position: absolute;
				top: 0;
				transform: scaleX(-1);
				opacity: 0;
			}
		}
	}

	h3 {
		font-family: 'Poppins';
		font-weight: 700;
		font-size: 16px;
		line-height: 1.2;
		text-align: center;
	}

	p {
		font-family: 'Poppins';
		font-weight: 700;
		font-size: 14px;
		line-height: 1.5;
		letter-spacing: 0.05em;
		text-align: center;
		color: ${({ theme }) => theme.gray[200]};
	}

	${(props) =>
		props.padding &&
		css`
			padding: ${(props) => props.padding};
		`}

	/* ${(props) =>
		props.padding &&
		css`
			padding: ${(props) => props.padding};
		`} */


	${(props) =>
		props.ContactForm &&
		css`
			margin-bottom: max(5.833vw, 50px);

			@media only screen and (max-width: 768px) {
				flex-direction: column;
			}

			.title {
				width: 32.8%;
				background-repeat: no-repeat;
				background-size: cover;
				background-color: ${({ theme }) => theme.primary};
				border-radius: 25px 0 0 25px;

				@media only screen and (max-width: 768px) {
					width: 100%;
					border-radius: 25px 25px 0 0;
				}

				h2 {
					margin: unset;
					padding: max(8.646vw, 40px) max(2vw, 30px);
					background: linear-gradient(
						145deg,
						#2c2c2c 0%,
						#121212 100%
					);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
			.form {
				width: 67.2%;

				@media only screen and (max-width: 768px) {
					width: 100%;
				}

				form {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					row-gap: max(2vw, 22px);
					padding: min(42px, 3vw) min(100px, 6vw) min(42px, 3vw) min(145px, 6vw);

					@media only screen and (max-width: 600px) {
						padding-block: 8vw;
					}

					.name, .phone, .email, .company {
						width: 45%;

						@media only screen and (max-width: 575px) {
							width: 100%;
						}
					}

					.message, .submit-wrapper {
						width: 100%;
					}

					.submit-wrapper {
						
						background: ${({ theme }) => theme.gradient[300]};
						box-shadow: -4px -4px 10px ${({ theme }) => theme.shadow[100]},
							4px 4px 10px ${({ theme }) => theme.shadow[900]},
							inset 0px 4px 40px
								${({ theme }) => theme.shadow[800]};
						border-radius: min(25px, 2vw);

						button {
							.arrows {
								left: 0;
							}
						}

						&:hover {
							button {
								background-color: ${({ theme }) =>
									theme.primary};

								.arrows {
									left: 4px;

									svg path {
										fill: ${({ theme }) =>
											theme.primary} !important;
									}
								}
							}
						}
						&:active {
							transform: translateY(5px);
						}

						button {
							border: unset;
							border-radius: min(25px, 2vw);
							width: 100%;
							padding-block: min(13px, 2vw);
							font-family: 'Poppins';
							font-weight: 700;
							font-size: 16px;
							line-height: 1.2;
							background-color: ${({ theme }) => theme.gray[50]};
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							background-clip: text;
							text-fill-color: transparent;
							cursor: pointer;
							column-gap: 5px;
							transition: all 0.3s ease;

							/* &:hover {
								background-color: ${({ theme }) => theme.primary};
							} */
						}
					}
				}
			}
		`}

	${(props) =>
		props.footer &&
		css`
			padding-block: min(59px, 8vw) min(23px, 4vw);
			z-index: 10;

			.top-footer {
				margin-bottom: min(35px, 5vw);

				@media only screen and (max-width: 920px) {
					flex-direction: column;
					align-items: center;
					row-gap: 4vw;
				}
				@media only screen and (max-width: 425px) {
					row-gap: 8vw;
				}

				.contact-ways {
					column-gap: max(1.667vw, 15px);

					@media only screen and (max-width: 920px) {
						flex-direction: column;
						align-items: center;
						row-gap: 3vw;
					}
					@media only screen and (max-width: 425px) {
						row-gap: 8vw;
					}

					div {
						a {
							column-gap: 8px;
							font-family: 'Poppins';
							font-size: 16px;
							line-height: 1.2;
							color: ${({ theme }) => theme.gray[300]};

							&:hover {
								color: ${({ theme }) => theme.primary};

								svg{
									path{
										fill:  ${({ theme }) => theme.primary};

										&:nth-of-type(2){
											fill:  ${({ theme }) => theme.gray[700]};

										}
									}
								}
							}
						}
					}
				}
			}

			.bottom-footer {
				margin-top: min(32px, 5vw);

				a{
					color: inherit;
					font-weight: 700;
					font-size: 14px;
					line-height: 1.5;

					&:hover{
						color: ${({ theme }) => theme.primary_darker};
					}
				}

				@media only screen and (max-width: 920px) {
					flex-direction: column;
				}
			}
		`}

	${(props) =>
		props.isProject === true &&
		css`
		transition-property: opacity, transform, top, left, box-shadow !important;
		// animation: unoverCard 0.2s ease-out forwards;
		top: 0;
		left: 0;

		&:hover{
			// animation: hoverCard 0.2s ease-out forwards;
			box-shadow: -5.1px -5.1px 10.2px ${({ theme }) => theme.shadow[100]},
			14px 14px 14px ${({ theme }) => theme.shadow[850]},
			inset 0px 4.08px 42.84px ${({ theme }) => theme.shadow[800]};
		}

		a {
				display: flex;
				flex-direction: column;
				height: 100%;

				&:hover{
					.image-wrapper{
						img{
							transform: scale(1.05);
						}
					}

					// h3{
					// 	color: ${({ theme }) => theme.primary};
					// }
				}

				.image-wrapper {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-grow: 1;
					overflow: hidden;
					border-radius: min(25px,2vw);

					&.height-short {
						height: 64%;
						@media only screen and (max-width: 768px) {
							height: 50%;
						}
					}
					&.height-long {
						height: 85.4%;

						@media only screen and (max-width: 768px) {
							height: 50%;
						}
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}

				.text-container {
					/* max-height: 135px;
					margin-top: auto; */

					&.height-short {
						height: 36%;
						@media only screen and (max-width: 768px) {
							height: 50%;
						}
					}
					&.height-long {
						height: 14.6%;
						@media only screen and (max-width: 768px) {
							height: 50%;
						}
					}
				}
				p,
				h3 {
					text-align: start;
				}
				h3 {
					color: ${({ theme }) => theme.gray[100]};
					margin-top: min(32px, 3vw);
					margin-bottom: 8px;
				}
				p {
					@media only screen and (max-width: 768px) {
						line-height: 1.2;
					}
				}
			}
	`}



	${({ rows }) =>
		rows &&
		css`
		grid-row:span ${rows};
	}`}

	${({ columns }) =>
		columns &&
		css`
		grid-column: span ${columns};
	}`}
`;

export const ServiceDesign = styled.div`
	position: relative;
	${({ gridArea }) =>
		gridArea &&
		css`
		grid-area: ${gridArea};
	}`}
	padding-inline-start: min(41px, 6vw);
	border-inline-start: 1px ${({ theme }) => theme.primary} dashed;
	border-block-end: 1px ${({ theme }) => theme.primary} dashed;
	border-radius: 0 max(1.3vw, 10px);

	&:not(:first-child) {
		margin-top: -25px;
	}

	&:last-child {
		border-inline-start: none;
		border-block-end: none;
	}

	.text {
		font-weight: 400;
		font-size: 16px;
		line-height: 1.5625;
		letter-spacing: 0.05em;
		color: ${({ theme }) => theme.gray[200]};
		margin-bottom: max(3vw, 30px);
	}

	.number-wrapper {
		left: 0;
		top: 0;
		transform: translateX(-50%);
	}

	@media only screen and (max-width: 768px) {
		grid-area: unset;

		h5 {
			margin-top: max(3vw, 30px);
		}

		&:not(:first-child) {
			margin-top: 0;
			.number-wrapper {
				top: 0;
				transform: translate(-50%, -50%);
			}
		}

		&:nth-child(even) {
			padding-inline-end: min(41px, 6vw);
			border-inline-end: 1px ${({ theme }) => theme.primary} dashed;
			border-inline-start: none;
			border-radius: max(1.3vw, 10px) 0;
			.number-wrapper {
				left: auto;
				right: 0;
				transform: translate(50%, -50%);
			}
		}
	}
`;

export const LineStyle = styled.hr`
	border: 1px ${({ theme }) => theme.primary} dashed;

	${(props) =>
		props.margin_bottom &&
		css`
			margin-bottom: ${(props) => props.margin_bottom};

			@media only screen and (max-width: 768px) {
				margin-top: 80px;
			}
		`}
	${(props) =>
		props.margin_top &&
		css`
			margin-top: ${(props) => props.margin_top};
		`}
`;

export const FormInputStyle = styled.div`
	label {
		font-family: 'Inter';
		font-weight: 700;
		font-size: 16px;
		line-height: 1.1875;
		color: ${({ theme }) => theme.gray[50]};
		margin-bottom: min(8px, 2vw);
	}
	input,
	textarea {
		webkit-appearance: none;
		background-color: unset;
		border: unset;
		outline: unset;
		height: max(2.083vw, 20px);
		font-size: 18px;
		font-style: italic;
		font-weight: 200;
		color: ${({ theme }) => theme.gray[50]};
		border-bottom: 1px solid #9b9b9b;
		&[type='submit'] {
			background: ${({ theme }) => theme.gradient[300]};
			box-shadow: -5.1px -5.1px 10.2px ${({ theme }) => theme.shadow[200]},
				5.1px 5.1px 10.2px ${({ theme }) => theme.shadow[900]},
				inset 0px 4.08px 42.84px ${({ theme }) => theme.shadow[800]};
			border-radius: min(25px, 2vw);
			font-weight: 700;
			font-size: 16px;
			line-height: 1.1875;
			font-family: 'Poppins';
			text-align: center;
			width: 100%;
		}
	}

	textarea {
		height: max(5vw, 40px);
		resize: none;
	}
`;

export const EmployeesStyle = styled.div`
	margin-bottom: min(112px, 15vw);

	.employees {
		gap: min(64px, 4vw) min(48px, 3vw);

		@media only screen and (max-width: 768px) {
			gap: 6vw;
		}
	}

	.project {
		width: 22%;
		max-height: unset;
		height: unset;
		padding: min(38px, 4vw) min(51px, 3vw);

		@media only screen and (max-width: 768px) {
			padding: 8vw 4vw;
			width: min(226px, 40vw);
		}

		&:hover {
			img {
				transform: scaleX(-1);

				&:nth-child(2) {
					transform: scaleX(1);
					opacity: 1;
				}
			}
		}

		.image-wrapper {
			margin-bottom: min(20px, 3vw);
			height: unset;

			img {
				border-radius: 50%;
				aspect-ratio: 1;
			}
		}

		h3 {
			margin-bottom: 8px;
		}
	}
	.title {
		font-family: 'Poppins';
		font-size: clamp(24px, 3vw, 39px);
		line-height: 1.2;
		margin-bottom: min(64px, 8vw);
	}
`;

export const GalleryStyle = styled.div`
	.title {
		font-family: 'Poppins';
		font-size: clamp(24px, 3vw, 39px);
		line-height: 1.2;
		margin-bottom: min(64px, 8vw);
	}
`;

export const CursorFollowerStyle = styled.div`
	position: fixed;
	width: 100px;
	height: 100px;
	background: ${({ theme }) => theme.gray[200]};
	box-sizing: border-box;
	box-shadow: 0px 0px 30px 10px ${({ theme }) => theme.gray[200]};
	-webkit-box-shadow: 0px 0px 30px 10px ${({ theme }) => theme.gray[200]};
	-moz-box-shadow: 0px 0px 30px 10px ${({ theme }) => theme.gray[200]};
	border-radius: 50%;
	opacity: 0.08;
	filter: blur(8px);
	pointer-events: none;
	transform: translate(-50%, -50%);
	transition: all 0.1s ease;
	z-index: 1;
`;

export const RelatedProjectsWrapper = styled.div`
	@media only screen and (max-width: 768px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;

		div {
			width: 100% !important;
			&:last-child {
				grid-area: 2/1/3/3;
			}
		}
	}
`;
