import styled, { css } from 'styled-components';

export const CategoryBlock = styled.div`
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 12px;
    font-weight: 700;
    background:  ${({ theme }) => theme.gray[700]};
    box-shadow:  4px 4px 10px ${({ theme }) => theme.shadow[600]},
                -2px -2px 10px ${({ theme }) => theme.shadow[300]};
    color: ${({ theme }) => theme.gray[200]};
    &:hover{
        color: ${({ theme }) => theme.primary};
    }
    &.active{
        color: ${({ theme }) => theme.gray[700]};
        background: ${({ theme }) => theme.primary};
    }
`;

export const CategoriesWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2vw;
`;
