import React, { useContext, useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import EditProjectBtn from '../Components/Admin Panel/EditProjectBtn';
import ProjectCard from '../Components/ProjectCard';
import AppContext from '../Contexts/AppContext';
import { SERVER_ENDPOINT } from '../services';
import { useParams } from 'react-router-dom';
import {
	Container,
	FlexBox,
	LineStyle,
	RelatedProjectsWrapper,
} from '../Styles/GeneralStyles';
import { GridBlock } from '../Styles/Projects Page Styles/GridStyles';
import { GetProjectBySlug } from '../services/api';
import AdminNav from '../Components/Admin Panel/AdminNav';
import EditRelatedProjects from '../Components/Admin Panel/EditRelatedProjects';
import { RespGrid } from '../Styles/Projects Page Styles/ProjectsPageStyle';
import Skeleton from 'react-loading-skeleton';
import { separateWords } from '../services/separateWords';

function SingleProject() {
	const { currentProject, setCurrentProject, screenSize } =
		useContext(AppContext);
	const { loggedInUser } = useContext(AppContext);
	const [projectSlug, setProjectSlug] = useState(useParams().projectname);
	const [layout, setLayout] = useState([]);
	const [relatedProjects, setRelatedProjects] = useState([]);

	const handleGetProjectBySlug = async () => {
		const res = await GetProjectBySlug(projectSlug);
		// console.log(res)
		if (res.success && res.data) {
			setCurrentProject({ ...res.data });
			if (res.data.gallery.length) {
				setLayout(
					res.data.gallery.map((img) => {
						return img?.order ? img?.order : null;
					}),
				);
			}
			if (res.data.related_projects.length) {
				setRelatedProjects(res.data.related_projects);
			}
		}
	};

	const calcGap = (number) => {
		return (screenSize.current / 1920) * number > 20
			? (screenSize.current / 1920) * number
			: 20;
	};
	const calcWidth = (number) => {
		return screenSize.current > 1375
			? number
			: screenSize.current * 0.9 + calcGap(64) * 2;
	};

	useEffect(() => {
		// console.log('currentProject', currentProject)
		handleGetProjectBySlug();
	}, []);

	useEffect(() => {
		if (currentProject?.gallery?.length) {
			setLayout(
				currentProject.gallery.map((img) => {
					return img?.order ? img?.order : null;
				}),
			);
		}
	}, [currentProject]);

	return (
		<Container>
			{Object.keys(loggedInUser).length > 0 &&
				screenSize.current > 768 && <AdminNav />}
			<FlexBox
				className='hero'
				padding_top={'max(10vw, 100px)'}
				margin_bottom={'max(12vw, 100px)'}
				text_center={'center'}
			>
				<h1 className='hero-title-home primary'>
					{
						currentProject?.project_title 
						?
						separateWords(currentProject?.project_title)
						:
						<Skeleton width='52vw' />
					}
				</h1>
				<h3>
					{
						currentProject?.project_description 
						?
						currentProject?.project_description
						:
						<Skeleton width='60vw' count={3}/>
					}
				</h3>
				<h5>
					{
						currentProject?.project_subtitle
						?
						currentProject?.project_subtitle
						:
						<Skeleton width='22vw' />
					}
				</h5>
			</FlexBox>
			<div>
				{currentProject?.gallery?.length > 0 &&
					screenSize.current > 768 ? (
					<GridLayout
						style={{ marginInline: `-${calcGap(64)}px` }}
						layout={layout}
						className='layout'
						cols={3}
						rowHeight={300} //ARIEL do func that will do this generic
						width={calcWidth(1363)}
						isResizable={false}
						isDraggable={false}
						isDroppable={false}
						margin={[calcGap(64), calcGap(64)]}
					>
						{currentProject?.gallery?.map((image, key) => {
							return (
								<GridBlock
									key={`${image?.order?.i}`}
									className='flex grid-item'
								>
									<LazyLoadImage
										src={`${
											SERVER_ENDPOINT + image.img_url
										}`}
										alt={`${currentProject.project_title} project pic`} 
									/>
								</GridBlock>
							);
						})}
					</GridLayout>
				) : (
					<RespGrid>
						{currentProject?.gallery?.map((image, key) => {
							return (
								<GridBlock
									key={`${image?.order?.i}`}
									className='single-project-img'
								>
									<LazyLoadImage
										src={`${
											SERVER_ENDPOINT + image.img_url
										}`}
										alt={`${currentProject.project_title} project pic`}
									/>
								</GridBlock>
							);
						})}
					</RespGrid>
				)}
			</div>
			<div className={'related-projects'}>
				<div>
					<h3 className='title relative'>
						Additional Projects
						{Object.keys(loggedInUser).length > 0 && (
							<EditRelatedProjects
								currentProject={currentProject}
								setRelatedProjects={setRelatedProjects}
							/>
						)}
					</h3>
				</div>
				<RelatedProjectsWrapper
					className={'wrapper flex justify-between'}
				>
					{relatedProjects.length > 0 &&
						relatedProjects.map((project, key) => (
							<ProjectCard
								index={key}
								className='project related-project'
								project_data={project}
								paddingVal={
									'min(14px, 2vw) min(14px, 2vw) min(25px, 3vw)'
								}
								staticHeight={true}
							/>
						))}
				</RelatedProjectsWrapper>
				<LineStyle margin_bottom={'min(72px, 9vw)'} />
			</div>
			{Object.keys(loggedInUser).length > 0 && (
				<div
					className={
						'relative edit-btns flex justify-center align-center'
					}
					style={{ minHeight: '5vw' }}
				>
					<EditProjectBtn
						type={'edit'}
						handleGetProject={handleGetProjectBySlug}
					/>
					<EditProjectBtn type={'delete'} />
				</div>
			)}
		</Container>
	);
}

export default SingleProject;
