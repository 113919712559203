import React, { useContext, useEffect, useState } from 'react'
import { EditBtn } from '../../Styles/Admin Panel Styles/ButtonStyles'
import { ReactComponent as EditIcon } from '../../assets/imgs/Admin Panel/edit_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as CloseIcon } from '../../assets/imgs/Admin Panel/close_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as AddIcon } from '../../assets/imgs/Admin Panel/add_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as DeleteIcon } from '../../assets/imgs/Admin Panel/delete_forever_FILL0_wght400_GRAD0_opsz24.svg'
import { Modal, ModalContainer } from '../../Styles/Admin Panel Styles/ModalStyles';
import ProjectEdit from './ProjectEdit';
import EditProjectsLayout from './EditProjectsLayout';
import AppContext from '../../Contexts/AppContext';
import DeleteProjectLayout from './DeleteProjectLayout';

function EditProjectBtn({currentCategory, type, handleGetProjects, handleGetProject}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen)
    };

    
    return (
        <>
            <EditBtn onClick={handleOpenModal} className={'edit-proj'}>
                {type==='add_new' &&
                    <>
                        <span>ADD NEW PROJECT</span>
                        <AddIcon/>
                    </>   
                }
                {
                    type==='edit_layout' && 
                    <>
                        <span>EDIT LAYOUT</span>
                        <EditIcon/>
                    </>
                }
                {
                    type==='edit' && 
                    <>
                        <span>EDIT PROJECT</span>
                        <EditIcon/>
                    </>
                }
                {
                    type==='delete' && 
                    <>
                        <span>DELETE PROJECT</span>
                        <DeleteIcon/>
                    </>
                }
            </EditBtn>
            <Modal
                className={isModalOpen ? 'open' : 'closed'}
                onClick={handleOpenModal}
            >
                <ModalContainer onClick={e => e.stopPropagation()} dark>
                    <div className='scrollable flex-column relative'>
                        <EditBtn onClick={handleOpenModal} className='abs-close'>
                            <CloseIcon/>
                        </EditBtn>
                        {
                            (type === 'add_new' || type === 'edit') &&
                            <ProjectEdit
                                handleGetProjects={handleGetProjects}
                                handleOpenModal={handleOpenModal} 
                                type={type}
                                currentCategory={currentCategory}
                                handleGetProject={handleGetProject}
                            />
                        }
                        { //todo
                            type==='edit_layout' && 
                            <EditProjectsLayout 
                                handleOpenModal={handleOpenModal}
                                currentCategory={currentCategory}
                                handleGetProjects={handleGetProjects}
                            />
                        }
                        {
                            type==='delete' && <DeleteProjectLayout handleOpenModal={handleOpenModal} />
                        }
                    </div>
                </ModalContainer>
            </Modal>
        </>
    )
}

export default EditProjectBtn