import { Dropzone, FileItem } from '@dropzone-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../Contexts/AppContext';
import { CreateSubField, RemoveImageField, RemoveSubField, UploadFile } from '../../services/api';
import { ContainedButton, EditBtn } from '../../Styles/Admin Panel Styles/ButtonStyles';
import { ImagesContainerLg, UploadContainer } from '../../Styles/Admin Panel Styles/Containers';
import { SERVER_ENDPOINT } from '../../services';
import { GridItem } from '../../Styles/Admin Panel Styles/Containers';
import { ReactComponent as DeleteIcon } from '../../assets/imgs/Admin Panel/delete_forever_FILL0_wght400_GRAD0_opsz24.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function ImagesEdit({
    field, 
    handleOpenModal, 
    uploadingStatus, 
    isUploadVisible, 
    setIsUploadVisible, 
    page_id,
    multiple
}) {
    const {handleGetFields} = useContext(AppContext);
    const [imageFiles, setImageFiles] = useState([]);
    const [imageSrc, setImageSrc] = useState(undefined);
    const [imgsToDelete, setImgsToDelete] = useState([]);
    const [existingImgs, setExistingImgs] = useState(field?.children?.length ? field?.children : []);

    const handleRemoveImgFromGallery = (img_id) => {
        const tmp_imgs_to_delete = [...imgsToDelete];
        if(tmp_imgs_to_delete.includes(img_id)) return;
        setImgsToDelete([...tmp_imgs_to_delete, img_id]);
        const new_imgs = existingImgs.filter(img=> img.id !== img_id);
        setExistingImgs(new_imgs);
      }
    
    const handleOnClose = () => {
        setImageFiles([]);
        handleOpenModal();
    }

    const handleOnChangeFile = (incommingFiles) => {
        setImageFiles(incommingFiles);
    };

    const handleOnDeleteFile = (id) => {
        setImageFiles(imageFiles.filter((x) => x.id !== id));
    };

    const handleOnSeeFile = (imageSource) => {
        setImageSrc(imageSource);
    };

    const handleOnUploadFiles = async (e) => {
        e.preventDefault();
        if(imageFiles.length){
            const allNewlyAddedImgs = [];
            const processFiles = async () => {
                for (let i = 0; i < imageFiles.length; i++) {
                    const image = imageFiles[i];
                    //upload image to server
                    const img_data_res = await UploadFile(image.file);
                    //create img field
                    if (img_data_res && img_data_res.data){
                        const data = {
                            field_id: field.id,
                            subfield_name: img_data_res.data?.filename,
                            subfield_content: img_data_res.data?.file_url,
                            subfield_type: 'image',
                        }
                        const image_subfield = await CreateSubField(data);
                        if(image_subfield && image_subfield.data) allNewlyAddedImgs.push(image_subfield);
                    }
                }
                setImageFiles([]);
                setIsUploadVisible(!isUploadVisible)
    
                if (allNewlyAddedImgs.length > 0){
                    handleGetFields(page_id);
                }
            }
            processFiles();
        }
        if(imgsToDelete.length){
            for (let i = 0; i < imgsToDelete.length; i++) {
                const res = await RemoveSubField(imgsToDelete[i]);
                if(res.success){
                    const new_imgs = existingImgs.filter(img=> img.id !== imgsToDelete[i]);
                    setExistingImgs(new_imgs);
                }
            }
            setImgsToDelete([]);
        }
        handleGetFields(page_id);
        handleOpenModal();
    }

    useEffect(() => {
        setExistingImgs(field?.children);
    }, [field])
    

    return (
        <UploadContainer className='flex-column align-center'>
            <ImagesContainerLg className='flex justify-between wrap' style={{width: '100%', marginBottom: '2vw'}}>
                {
                    existingImgs?.length > 0 && existingImgs.map(child_img => (
                        <GridItem className="grid-item relative" key={child_img.id}>
                            <div className="img-wrapper">
                                <LazyLoadImage
                                    alt="the guy" 
                                    src={`${SERVER_ENDPOINT + child_img?.subfield_content}`}
                                />
                            </div>
                            <div className="btn-wrapper">
                            <EditBtn
                                onClick={()=>{handleRemoveImgFromGallery(child_img.id)}}
                                className='edit-proj' 
                                relative 
                                light 
                                fullwidth
                            > 
                                <DeleteIcon/> 
                                REMOVE
                            </EditBtn>
                            </div>
                        </GridItem>
                    ))
                }
            </ImagesContainerLg>
            <ContainedButton
                variant="contained" 
                className={isUploadVisible && 'bordered'}
                onClick={()=>setIsUploadVisible(!isUploadVisible)}
                secondary
                fullWidth
            >
                UPLOAD NEW IMAGES
            </ContainedButton>
            <div className={`dropzone-container ${isUploadVisible ? 'visible' : 'hidden'}`}>
                <Dropzone
                    label={'Drop your files here'}
                    onChange={handleOnChangeFile}
                    value={imageFiles}
                    maxFiles={multiple === true ? 10  : 1}
                    maxFileSize={7998000}
                    accept={'image/*'}
                >
                    {imageFiles.map((file) => (
                        <FileItem key={file.id}
                        {...file} 
                        onDelete={handleOnDeleteFile}
                        onSee={handleOnSeeFile}
                        preview
                        info
                        hd
                        />
                    ))}
                </Dropzone>
            </div>
            {uploadingStatus === undefined && (imageFiles.length > 0 || imgsToDelete.length > 0) && 
                <div className={'btns flex justify-center'} style={{marginTop: '2vw'}}>
                    <ContainedButton
                        variant="contained" 
                        className="primary-brand-bckg"
                        onClick={handleOnUploadFiles}
                        primary
                    >
                        Save
                    </ContainedButton>
                    <ContainedButton
                        variant="contained" 
                        className="primary-brand-bckg"
                        onClick={handleOnClose}
                        secondary
                    >
                        Cancel 
                    </ContainedButton>
                </div>
            }
        </UploadContainer>
    )
}

export default ImagesEdit