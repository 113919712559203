import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../Contexts/AppContext';
import { ProjectsLayoutContainer } from '../../Styles/Admin Panel Styles/ProjectsLayoutStyles';
import GridLayout from 'react-grid-layout';
import { SERVER_ENDPOINT } from '../../services';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GridItem } from '../../Styles/Admin Panel Styles/Containers';
import { ContainedButton } from '../../Styles/Admin Panel Styles/ButtonStyles';
import { updateProjectOrder } from '../../services/api';
import Loader from './Loader';

function EditProjectsLayout({handleOpenModal, currentCategory, handleGetProjects}) {
	const {projects, screenSize} = useContext(AppContext);
	const [isLoading, setIsLoading] = useState(false);
	const [layout, setLayout] = useState(
		projects?.length &&
			projects?.map((project) => {
				return project?.order;
			}),
	);
	const [updatedProjectsOrder, setUpdatedProjectsOrder] = useState([]);

	const getPxFromVw = () => {
		const windowWidth = screenSize.current;
		return (windowWidth * 28) / 100; //35vw
	};

	const getPxFromVh = () => {
		const containerWidth = getPxFromVw();
		return containerWidth / 4;
	};

	const handleOnMoveCard = (e) => {
		let tmp_arr = [];
		const tmp_projects = [...projects];
		for (let n = 0; n < e.length; n++) {
			const project_with_order_to_update = tmp_projects.find(
				(proj) => proj.order.i === e[n].i,
			);
			if (project_with_order_to_update) {
				const { w, h, x, y, i } = e[n];
				tmp_arr.push({
					id: project_with_order_to_update.id,
					order: { w, h, x, y, i },
				});
			}
		}

		setUpdatedProjectsOrder(tmp_arr);
	};

	const handleUpdateLayout = async () => {
		setIsLoading(true);
		let tmp_arr = [];
		for (let i = 0; i < updatedProjectsOrder.length; i++) {
			const res = await updateProjectOrder({...updatedProjectsOrder[i], category: currentCategory});
			if (res.success && res.data) {
				tmp_arr.push({ ...res.data });
			} 
		}
		
		setIsLoading(false);
		handleOpenModal();
		handleGetProjects();
	};

	useEffect(() => {
		// console.log(layout)
		setLayout(
			projects?.length &&
				projects?.map((project) => {
					return project?.order
						? project?.order
						: null;
				}),
		);
	}, [projects]);

	return (
		<ProjectsLayoutContainer>
			{isLoading && <Loader />}
			{!isLoading && (
				<>
					{projects.length > 0 && layout.length > 0 && (
						<>
							<GridLayout
								className='layout layout-edit'
								layout={layout}
								cols={3}
								isDragable={true}
								isResizable={true}
								onDragStop={(e) => handleOnMoveCard(e)}
								onResizeStop={(e) => handleOnMoveCard(e)}
								rowHeight={getPxFromVh()}
								width={getPxFromVw()}
							>
								{projects.map((project, key) => {
									return (
										<GridItem
											key={`${project?.order?.i}`}
											className='grid-item'
										>
											<div className='img-wrapper'>
												<LazyLoadImage
													alt={project?.project_title}
													src={`${
														SERVER_ENDPOINT +
														project.gallery.find(
															(img) =>
																img?.is_main,
														)?.img_url
													}`}
												/>
											</div>
											<div className='text-wrapper'>
												<h4>
													{project?.project_title}
												</h4>
											</div>
										</GridItem>
									);
								})}
							</GridLayout>
							<div className='btns flex justify-center align-center'>
								<ContainedButton
									primary
									onClick={handleUpdateLayout}
								>
									{' '}
									SAVE{' '}
								</ContainedButton>
							</div>
						</>
					)}
				</>
			)}
		</ProjectsLayoutContainer>
	);
}

export default EditProjectsLayout;
