import React, { useContext, useEffect, useState } from 'react'
import { ReactComponent as EditIcon } from '../../assets/imgs/Admin Panel/edit_FILL0_wght400_GRAD0_opsz24.svg';
import AppContext from '../../Contexts/AppContext';
import {  updatePageField } from '../../services/api';
import { ContainedButton, EditBtn, TransparentBtn } from '../../Styles/Admin Panel Styles/ButtonStyles';
import { Input } from '../../Styles/Admin Panel Styles/InputStyles';
import { Modal, ModalContainer } from '../../Styles/Admin Panel Styles/ModalStyles';
import ProjectEdit from './ProjectEdit';
import ImagesEdit from './ImagesEdit';
import CategoriesEdit from './CategoriesEdit';
import BlocksEdit from './BlocksEdit';
import TeamEdit from './TeamEdit';

function EditFieldBtn({className, field, page_id, content_type, multiple, children}) {
    const {pageFields, setPageFields} = useContext(AppContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fieldObj, setFieldObj] = useState(field ? field : {});
    const [isUploadVisible, setIsUploadVisible] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen)
    };

    const handleUpdateField = async(field_id)=> {
		const data = {
			field_id,
			field_content: fieldObj.field_content
		}
		const res = await updatePageField(data);
		if(res.success && res.data){
			let prev_fields = {...pageFields};
			for (const key in prev_fields) {
				if (prev_fields[key].id === res.data.id) {
					prev_fields[key] = res.data;
				}
			}
			setPageFields(prev_fields);
            handleOpenModal();
		}
	}

    const hadleSetField = (content) => {
        setFieldObj({ ...field, field_content: content})
    }

    useEffect(() => {
      setFieldObj(field);
    }, [field])
    

    return (
        <>
            <EditBtn 
                onClick={handleOpenModal} 
                className={`${className} ${(content_type==='blocks' || content_type==='team_blocks') && 'child-field-popup'}`}
            >
                {(content_type==='blocks' || content_type==='team_blocks') && 'EDIT'}
                <EditIcon/>
            </EditBtn>
            <Modal
                className={isModalOpen ? 'open' : 'closed'}
                onClick={handleOpenModal}
            >
                <ModalContainer onClick={e => e.stopPropagation()} dark={(content_type==='blocks' || content_type==='team_blocks' || content_type === 'about_gallery') ? true : false}>
                    <div className='scrollable flex-column relative'>
                        {content_type==='blocks' &&
                            <BlocksEdit
                                field={fieldObj}
                                handleOpenModal={handleOpenModal}    
                                isUploadVisible={isUploadVisible}
                                setIsUploadVisible={setIsUploadVisible}
                                page_id={page_id}
                            />
                        }
                        {content_type==='team_blocks' &&
                            <TeamEdit
                                field={fieldObj}
                                handleOpenModal={handleOpenModal}    
                                isUploadVisible={isUploadVisible}
                                setIsUploadVisible={setIsUploadVisible}
                                page_id={page_id}
                            />
                        }
                        {content_type === 'image' && 
                            <ImagesEdit 
                                field={fieldObj}
                                handleOpenModal={handleOpenModal}    
                                isUploadVisible={isUploadVisible}
                                setIsUploadVisible={setIsUploadVisible}
                                page_id={page_id}
                                multiple={multiple}
                             />
                        }
                        {content_type === 'about_gallery' && <>
                                <div className='flex-column' style={{gap: '5vmin'}}>
                                    <Input 
                                        defaultValue={fieldObj?.field_content} 
                                        placeholder={'Write content here...'} 
                                        onChange={(e)=> hadleSetField(e.target.value)} 
                                    />
                                    <ImagesEdit 
                                        field={children}
                                        handleOpenModal={handleOpenModal}    
                                        isUploadVisible={isUploadVisible}
                                        setIsUploadVisible={setIsUploadVisible}
                                        page_id={page_id}
                                        multiple={multiple}
                                    />
                                </div>
                        
                        </>}
                        {!content_type && 
                            <>
                                <div>
                                    <Input 
                                        defaultValue={field?.field_content} 
                                        placeholder={'Write content here...'} 
                                        onChange={(e)=> hadleSetField(e.target.value)} 
                                        light
                                    />
                                </div>
                                <div className='btns flex justify-center align-center'>
                                    <ContainedButton onClick={()=>handleUpdateField(field?.id)} primary> SAVE </ContainedButton>
                                    <ContainedButton secondary onClick={handleOpenModal}> CANCEL </ContainedButton>
                                </div>
                            </>
                        }
                    </div>
                </ModalContainer>
            </Modal>
        </>
    )
}

export default EditFieldBtn