export const lightTheme = {
	isDark: false,
	primary: 'rgba(162, 200, 58, 0.8)',
	primary_darker: '#a1c83a',
	secondary: ' rgba(51, 33, 99, 0.5)',
	//from darkest to lightest
	gray: {
		50: '#2c2c2c',
		100: '#383838',
		200: '#595959',
		300: '#3B3B3B',
		500: '#9c9c9c',
		700: '#EAECF0',
		800: '#fcfcfc',
		900: '#fff',
	},
	shadow: {
		100: 'rgb(255, 255, 255)',
		200: 'rgba(255, 255, 255, 0.9)',
		300: '#FAFBFF',
		400: '#FAFBFF',
		500: 'rgba(200, 200, 200, 0.8)',
		600: 'rgba(190, 190, 190, 0.7)',
		700: '#A6ABBD',
		800: '#F7F7F7',
		850: 'rgb(180, 199, 213)',
		900: 'rgba(180, 199, 213, 0.5)',
	},
	gradient: {
		300: 'linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #EAECF0; background-blend-mode: soft-light, normal;',
		400: 'linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #EAECF0; background-blend-mode: soft-light, normal;',
	},
};

export const darkTheme = {
	isDark: true,
	primary: 'rgba(162, 200, 58, 0.8)',
	primary_darker: '#a1c83a',
	secondary: 'rgba(204, 99, 94, 0.8)',
	//from lightest to darkest
	gray: {
		50: '#fff',
		100: '#fcfcfc',
		200: '#b0b0b0',
		300: '#CECECE',
		500: '#585858',
		700: '#383838',
		800: '#2c2c2c',
		900: '#121212',
	},
	shadow: {
		100: 'rgba(255, 255, 255, 0.1)',
		200: 'rgba(255, 255, 255, 0.25)',
		300: 'rgba(79, 79, 79, 0.3)',
		400: 'rgba(79, 79, 79, 0.9)',
		500: 'rgba(33, 33, 33, 0.2)',
		600: 'rgba(33, 33, 33, 0.5)',
		700: 'rgba(33, 33, 33, 0.9)',
		800: 'rgba(0, 0, 0, 0.06)',
		850: 'rgba(0, 0, 0, 0.15)',
		900: 'rgba(0, 0, 0, 0.25)',
	},
	gradient: {
		300: 'linear-gradient(127.27deg,#606060 5.8%,rgba(65, 65, 65, 0.12) 102.7%),#383838',
		400: 'linear-gradient(127.27deg,#585858 1.18%,rgba(65, 65, 65, 0.12) 102.7%),#383838;',
	},
};

