import styled, { css } from 'styled-components';

export const NotFoundContainer = styled.div`
	width: min(1440px, 100%);
	margin-inline: auto;
	min-height: 80vh;
`;

export const NotFoundWrapper = styled.div`
	padding-inline: 12px;

	h1 {
		text-align: unset;
		font-size: clamp(80px, 10vw, 160px);
		font-weight: 900;
		background-color: ${({ theme }) => theme.gray[800]};
		color: transparent;
		text-shadow: 4px 4px 1px var(--primary);
		-webkit-background-clip: text;
		-moz-background-clip: text;
		-moz-background-clip: text;
		margin-bottom: max(40px, 4vw);
	}

	div {
		font-size: clamp(18px, 2vw, 24px);
		margin-block: 0 20px;
		font-weight: 700;
		background-color: ${({ theme }) => theme.gray[800]};
		color: transparent;
		text-shadow: 1px 1px 0px ${({ theme }) => theme.gray[100]};
		-webkit-background-clip: text;
		-moz-background-clip: text;
		-moz-background-clip: text;
	}

	button {
		width: min(276px, 100%);
		margin-inline: auto;
		margin-top: 3vw;
		a {
			color: ${({ theme }) => theme.gray[100]};
		}
	}

	${(props) =>
		props.thankYou &&
		css`
			text-align: center;
			h1 {
				font-size: clamp(35px, 5vw, 65px);
				text-shadow: 2px 2px 1px var(--primary);
			}

			button {
				margin-inline: auto;
			}
		`}
`;
