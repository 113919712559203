import React, { useContext, useEffect, useState } from 'react'
import { UpdateSubfield, getAllProjectsCats } from '../../services/api'
import { Input } from '../../Styles/Admin Panel Styles/InputStyles'
import { InputWrapper } from '../../Styles/Admin Panel Styles/InputStyles';
import { Textarea } from '../../Styles/Admin Panel Styles/InputStyles';
import { ContainedButton } from '../../Styles/Admin Panel Styles/ButtonStyles';
import { NumberStyle } from '../../Styles/GeneralStyles';
import AppContext from '../../Contexts/AppContext';

function BlocksEdit({field, handleOpenModal, page_id}) {
  const {handleGetFields} = useContext(AppContext);
  const [childFields, setChildFileds] = useState(field?.children ? field.children : []);
  
  const handleOnSubfieldChange = (id, value, key) => {
    const tmp_fields = [...childFields];
    const field_to_change = tmp_fields.find(field=> +field.id === +id);
    field_to_change.subfield_content[`${key}`] = value;
    setChildFileds(tmp_fields);
  }

  const handleUpdateSubfields = async() => {
    const updated_subfields = [];
    for (let i = 0; i < childFields.length; i++) {
      const el = childFields[i];
      const res = await UpdateSubfield({
        id: el.id,
        subfield_content: el.subfield_content
      })
      if(res.success && res.data){
        updated_subfields.push(res.data);
      }
    }

    handleGetFields(page_id);
    handleOpenModal();

  }

  useEffect(() => {
    setChildFileds(field?.children);
  }, [field])

  return (
    <div className='flex-column' style={{gap: '5vw'}}>
        {childFields?.length > 0 && childFields.map((child_field, i)=>(
          <div className='flex align-center justify-between'>
            <NumberStyle
              className='number-wrapper'
            >
              <span>{`0${i + 1}`}</span>
            </NumberStyle>
            <div className='flex-column' style={{gap: 'max(16px, 0.8vw)', width: '85%'}}>
              <InputWrapper className='flex-column' style={{gap: '0.5vw'}}>
                <h4>Title:</h4>
                <Input 
                  defaultValue={child_field.subfield_content.title} 
                  id={child_field.id}
                  onChange={(e)=>{
                    handleOnSubfieldChange(e.target.id, e.target.value, 'title');
                  }}
                />
              </InputWrapper>
              <InputWrapper className='flex-column' style={{gap: '0.5vw'}}>
                <h4>Content:</h4>
                <Textarea 
                  defaultValue={child_field.subfield_content.content} 
                  rows={3}
                  id={child_field.id}
                  onChange={(e)=>{
                    handleOnSubfieldChange(e.target.id, e.target.value, 'content');
                  }}
                />
              </InputWrapper>
            </div>
          </div>
        ))}
        <div className='btns flex justify-center align-center'>
            <ContainedButton primary onClick={handleUpdateSubfields}> SAVE </ContainedButton>
            <ContainedButton secondary onClick={handleOpenModal}> CANCEL </ContainedButton>
        </div>
    </div>
  )
}

export default BlocksEdit