export const CustomSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        display: 'flex',
        background: 'rgba(50, 50, 50, 1)',
        boxShadow: `1px 1px 2px rgba(79, 79, 79, 0.3),
            -1px -1px 2px rgba(33, 33, 33, 0.5),
            inset -3px 3px 6px rgba(33, 33, 33, 0.2),
            inset -3px -3px 6px rgba(79, 79, 79, 0.9),
            inset 3px 3px 8px rgba(33, 33, 33, 0.9)`,
        borderRadius: `max(12px, 0.8vw)`,
        border: 'none',
        outline: 'none',
        padding: '8px 18px',
        color: '#fcfcfc',
        fontSize: 'max(16px, 1.1vw)',
    }),
    menu: (provided, state) => ({
        ...provided,
        background: 'rgba(50, 50, 50, 1)',
        boxShadow: `1px 1px 2px rgba(79, 79, 79, 0.3),
            -1px -1px 2px rgba(33, 33, 33, 0.5),
            inset -3px 3px 6px rgba(33, 33, 33, 0.2),
            inset -3px -3px 6px rgba(79, 79, 79, 0.9),
            inset 3px 3px 8px rgba(33, 33, 33, 0.9)`,
        borderRadius: `max(12px, 0.8vw)`,
        border: 'none',
        outline: 'none',
        padding: '8px 18px',
        color: '#fcfcfc',
        fontSize: 'max(16px, 1.1vw)',
        minHeight: '90px',
        position: 'relative',
        top: 0, 
        width: '100%',
    }),
    option: (provided, state) => ({
        ...provided,
        color: '#fcfcfc',
        cursor: 'pointer',
        background: 'unset',
        '&:hover': {
          color: 'var(--primary)',
          background: 'unset',
        },
        '&--is-disabled': {
          color: 'rgb(156, 156, 156)',
          pointerEvents: 'none',
        },
    }),
    multiValue: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        padding: '8px 20px',
        borderRadius: '12px',
        fontWeight: '700',
        fontSize: '16px',
        background: '#404040',
        boxShadow: '4px 4px 10px rgba(33, 33, 33, 0.5), -2px -2px 10px rgba(79, 79, 79, 0.3)',
        color: '#fcfcfc',
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: '#fcfcfc',
    }),
    multiValueRemove: (provided, state) => ({
         ...provided,
        background: 'unset',
        '&:hover': {
            background: 'unset',
        },
        svg: {
            transform: 'scale(1.3)',
        },
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        '.react-select__indicator': {
            'svg':{
                fill: '#fff',
                transform: 'scale(1.2)'
            },

            '&:hover':{
                'svg':{
                    fill: '#a1c83a'
                }
            }
        }
    }),
};