import styled, { css } from 'styled-components';

export const TransparentBtn = styled.button`
	display: flex;
	align-items: center;
	padding: 5px;
	border: none;
	outline: none;
	cursor: pointer;
	background: transparent;
	color: ${({ theme }) => theme.gray[100]};
	svg * {
		fill: ${({ theme }) => theme.gray[100]};
	}
	&:hover {
		color: ${({ theme }) => theme.primary};
		svg * {
			fill: ${({ theme }) => theme.primary};
		}
	}

	&.login-visibility{
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
	}
`;

export const EditBtn = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: max(30px, 2.5vw);
	aspect-ratio: 1;
	border-radius: 50%;
	border: none;
	outline: none;
	cursor: pointer;
	position: absolute;
	bottom: calc(100% + 10px);
	left: calc(100% + 10px);
	transform: translateY(50%);
	background: ${({ theme }) => theme.gray[700]};
	box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[300]},
		-1px -1px 2px ${({ theme }) => theme.shadow[600]},
		inset -3px 3px 6px ${({ theme }) => theme.shadow[500]},
		inset -2px -2px 5px ${({ theme }) => theme.shadow[400]},
		inset 3px 3px 10px ${({ theme }) => theme.shadow[700]};
	color: ${({ theme }) => theme.gray[100]};
	svg {
		transform: scale(0.8);
		* {
			fill: ${({ theme }) => theme.gray[100]};
		}
	}

	&:hover {
		svg * {
			fill: ${({ theme }) => theme.primary};
		}
	}

	&:active {
		transform: translateY(50%) scale(0.93);
	}

	${(props) =>
		props.relative &&
		css`
			position: relative;
			bottom: 0;
			left: 0;
			transform: unset;

			&:active {
				transform: scale(0.9);
			}
		`};

	${(props) =>
		props.light &&
		css`
			background: ${({ theme }) => theme.gray[500]};
			box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[100]},
				-1px -1px 2px ${({ theme }) => theme.shadow[500]},
				inset -3px 3px 6px ${({ theme }) => theme.shadow[400]},
				inset -3px -3px 6px ${({ theme }) => theme.shadow[300]},
				inset 3px 3px 8px ${({ theme }) => theme.shadow[600]};
		`};
	${(props) =>
		props.fullwidth &&
		css`
			width: 100% !important;
		`};

    &.edit-proj, &.child-field-popup {
        width: auto;
        border-radius: 10px;
        aspect-ratio: unset;
        padding: 10px 20px;


        &:hover{
            color: ${({ theme }) => theme.primary};
		}
    }

	&.clients-edit{
		bottom: calc(100% + 50px);
		left: 90%;
	}
`;

export const ContainedButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	padding: 10px 22px;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 0.5vw;
	font-weight: 700;
	font-size: max(16px, 1.1vw);
	color: ${({ theme }) => theme.gray[100]};
	${(props) =>
		props.primary &&
		css`
			background: rgba(135, 166, 50, 0.7);
			&:hover {
				background: rgba(135, 166, 50, 1);
			}
		`};
	${(props) =>
		props.secondary &&
		css`
			background: ${({ theme }) => theme.secondary};
			&:hover {
				background: rgba(204, 99, 94, 1);
			}
		`};
	${(props) =>
		props.fullWidth &&
		css`
			width: 100%;
		`};

	&.bordered {
		border-radius: 1.3vw 1.3vw 0 0;
		background: rgba(204, 99, 94, 0.3);
	}

	svg {
		transform: scale(0.8);
		* {
			fill: ${({ theme }) => theme.gray[100]};
		}
	}
`;
