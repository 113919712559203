import { createGlobalStyle } from 'styled-components';
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.gray[700]};
		color: ${({ theme }) => theme.gray[300]} !important;
    font-family: Poppins;
    transition: all 0.50s linear;
  }
  h1{
	font-weight: 700;
	letter-spacing: 0.08em;
	line-height: 1.2;
	font-size: max(3.906vw, 28px);
	letter-spacing: 0.08em;
	color: ${({ theme }) => theme.primary};
	margin-bottom: min(30px, 5vw);
	text-align: center;
  }
  h2{
	font-weight: 700;
	font-size: clamp(25px, 3.5vw,49px);
	line-height: 1.2;
	margin-bottom: max(3.333vw, 30px);
  }
  h3{
	font-weight: 300;
	font-size: clamp(18px, 2.2vw,25px);
	line-height:1.5;
  }
  h4{
	color: ${({ theme }) => theme.gray[100]};
	font-weight: 600;
	font-size: max(18px, 1vw);
	line-height: 1;
  }
  h5{
	font-weight: 700;
	font-size: 18px;
	line-height: 1.5;
	letter-spacing: 0.1em;
	text-transform: capitalize;
	margin-bottom: 5px;
  }
  ul{
	list-style-type: none;
  }
  li{
	color: ${({ theme }) => theme.gray[100]};
  }
`;
