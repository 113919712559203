import React, { useCallback, useContext, useEffect, useState } from 'react';
import Card from '../Components/Card';
import { Container, FlexBox, LineStyle } from '../Styles/GeneralStyles';
import {
	Grid,
	CategoryTitle,
	CategoriesStyle,
	RespGrid,
} from '../Styles/Projects Page Styles/ProjectsPageStyle';
import VaaMain from '../assets/imgs/vaa-main.png';
import ContactForm from '../Components/ContactForm';
import AdminNav from '../Components/Admin Panel/AdminNav';
import AppContext from '../Contexts/AppContext';
import EditFieldBtn from '../Components/Admin Panel/EditFieldBtn';
import { getAllProjects, getAllProjectsCats } from '../services/api';
import GridLayout from 'react-grid-layout';
import ProjectCard from '../Components/ProjectCard';
import EditCatsBtn from '../Components/Admin Panel/EditCatsBtn';
import EditProjectBtn from '../Components/Admin Panel/EditProjectBtn';
import Loader from '../Components/Admin Panel/Loader';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import Skeleton from 'react-loading-skeleton';
import { separateWords } from '../services/separateWords';

function Projects() {
	const {
		loggedInUser,
		handleGetFields,
		pageFields,
		projects,
		setProjects,
		setCurrentProject,
		projectsCategories,
		setProjectsCategories,
		screenSize,
	} = useContext(AppContext);
	const [currentCategory, setCurrentCategory] = useState(1);
	const [layout, setLayout] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [screenWidth, setScreenWith] = useState();

	const handleGetProjectsByCat = async () => {
		setIsLoading(true);
		const res = await getAllProjects(currentCategory);
		if (res.success && res.data) {
			setProjects(res.data);
		} else {
			setProjects([]);
			setLayout([]);
		}
		setTimeout(() => {
			setIsLoading(false);
		}, 300);
	};

	// make the gap between projects to change without refresh the page
	const handleScreenSizeChange = (event) => {
		setScreenWith(event.target.innerWidth);
	};
	useEffect(() => {
		window.addEventListener('resize', handleScreenSizeChange);

		return () => {
			window.removeEventListener('resize', handleScreenSizeChange);
		};
	}, []);

	const calcGap = (number) => {
		return (screenSize.current / 1920) * number > 20
			? (screenSize.current / 1920) * number
			: 20;
	};
	const calcWidth = (number) => {
		return screenSize.current > 1375
			? number
			: screenSize.current * 0.9 + calcGap(64) * 2;
	};

	useEffect(() => {
		currentCategory && handleGetProjectsByCat();
	}, [currentCategory]);

	useEffect(() => {
		const isNotEmptyAndAllHaveOrder =
			projects.length > 0 &&
			projects.every((project) =>
				Object.prototype.hasOwnProperty.call(project, 'order'),
			);
			console.log(projects)
		setLayout(
			isNotEmptyAndAllHaveOrder
				? projects?.map((project) => {
						return project.order;
				  })
				: [],
		);
	}, [projects]);

	

	useEffect(() => {
		setProjects([]);
		setCurrentProject({
			project_title: '',
			project_subtitle: '',
			project_description: '',
		});
		handleGetFields(3);
	}, []);

	return (
		<>
			{Object.keys(pageFields).length > 0 && (
				<Container>
					{Object.keys(loggedInUser).length > 0 &&
						screenSize.current > 768 && <AdminNav />}
					<FlexBox
						className='projects'
						padding_top={'max(14vw, 100px)'}
						margin_bottom={'max(14vw, 100px)'}
					>
						<h1 
							className='hero-title-home'
							// className={'gradient'}
						>
							{pageFields?.projects_main_title?.field_content ? 
								separateWords(pageFields?.projects_main_title?.field_content)
								:  
								<Skeleton width='22vw' />
							}
						</h1>
						<h3>
							{pageFields?.projects_main_subtitle
								?.field_content ? (
								pageFields?.projects_main_subtitle
									?.field_content
							) : (
								<Skeleton width='17vw' />
							)}
						</h3>
					</FlexBox>
					<CategoriesStyle className='flex justify-between relative'>
						{Object.keys(loggedInUser).length > 0 && (
							<EditCatsBtn
								page_id={3}
								projectsCategories={projectsCategories}
								setProjectsCategories={setProjectsCategories}
							/>
						)}
						{projectsCategories.length > 0 ? (
							screenSize.current > 768 ? (
								projectsCategories.map((category) => {
									return (
										<CategoryTitle
											key={category.id}
											className={
												category.id === currentCategory
													? 'category selected'
													: 'category'
											}
											onClick={() => {
												setCurrentCategory(category.id);
											}}
										>
											{category.category_name}
										</CategoryTitle>
									);
								})
							) : (
								<Swiper
								
									spaceBetween={35}
									slidesPerView={'1.3'}
									breakpoints={{
										440: {
											slidesPerView:'1.7',
										},
									}}
									style={{padding: '20px 8px'}}
									// centeredSlides={true}
									// centeredSlidesBounds={true}
									// freeMode={true}
									// slidesOffsetAfter={'100'}
									// parallax={true}
									// style={{
									// 	borderRadius: '25px',
									// 	// marginInline: 0,
									// }}
								>
									{projectsCategories.map((category) => {
										return (
											<SwiperSlide
												key={category.id}
												className={
													category.id ===
													currentCategory
														? 'category selected'
														: 'category'
												}
												onClick={() => {
													setCurrentCategory(
														category.id,
													);
												}}
											>
												{category.category_name}
											</SwiperSlide>
										);
									})}
								</Swiper>
							)
						) : (
							<Skeleton width='64vw' height={60} />
						)}
					</CategoriesStyle>
					{!isLoading && (
						<div>
							{projects.length > 0 &&
								layout.length > 0 &&
								(screenSize.current > 768 ? (
									<GridLayout
										style={{
											marginInline: `-${calcGap(64)}px`,
										}}
										layout={layout}
										className='layout'
										cols={3}
										rowHeight={345} 
										width={calcWidth(1363)} 
										isResizable={false}
										isDraggable={false}
										isDroppable={false}
										margin={[calcGap(64), calcGap(64)]} 
									>
										{projects.map((project, index) => {
											return (
												<div
													key={`${project?.order?.i}`}
													className={'hover-card'}
												>
													<ProjectCard
														index={index}
														className='project'
														project_data={project}
														paddingVal={
															'min(14px, 2vw) min(14px, 2vw) min(25px, 3vw)'
														}
													/>
												</div>
											);
										})}
									</GridLayout>
								) : (
									<RespGrid>
										{projects.map((project, key) => {
											return (
												<div
													key={`${project?.order?.i}`}
													className='project'
												>
													<ProjectCard
														index={key}
														project_data={project}
														paddingVal={
															'min(14px, 2vw) min(14px, 2vw) min(25px, 3vw)'
														}
													/>
												</div>
											);
										})}
									</RespGrid>
								))}
						</div>
					)}
					{isLoading && <Loader />}
					{Object.keys(loggedInUser).length > 0 && (
						<div className='relative edit-btns flex justify-center align-center'>
							{projects.length > 0 && layout.length > 0 && (
								<EditProjectBtn
									currentCategory={currentCategory}
									handleGetProjects={handleGetProjectsByCat}
									type={'edit_layout'}
								/>
							)}
							<EditProjectBtn
								handleGetProjects={handleGetProjectsByCat}
								projectsCategories={projectsCategories}
								type={'add_new'}
								currentCategory={currentCategory}
							/>
						</div>
					)}
					<LineStyle margin_bottom={'min(72px, 9vw)'} />
					<ContactForm />
				</Container>
			)}
			{Object.keys(pageFields).length === 0 && (
				<div
					className='flex align-center justify-center relative'
					style={{ width: '100%', height: '100vh' }}
				>
					<Loader />
				</div>
			)}
		</>
	);
}

export default Projects;
