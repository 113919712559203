import { axiosPost, axiosGet, axiosPut, axiosDelete, SERVER_ENDPOINT } from './index';

export const userSignIn = async (user_data) => {
	return await axiosPost(
		`${SERVER_ENDPOINT}/api/users/userSignIn`,
		user_data,
	);
};

export const fetchVerifiedUser = async () => {
	return await axiosGet(
		`${SERVER_ENDPOINT}/api/users/userVerify`,
	);
};

export const userLogout = async () => {
	return await axiosGet(
		`${SERVER_ENDPOINT}/api/users/userLogout`,
	);
};

export const createField = async (field_data) => {
	return await axiosPost(
		`${SERVER_ENDPOINT}/api/fields/createField`,
		field_data,
	);
};

export const CreateSubField = async (field_data) => {
	return await axiosPost(
		`${SERVER_ENDPOINT}/api/fields/CreateSubField`,
		field_data,
	);
};


export const getPageFields = async (page_id) => {
	return await axiosGet(
		`${SERVER_ENDPOINT}/api/fields/getFieldsByPage?page_id=${page_id}`
	);
};

export const getSubfields = async (field_id) => {
	return await axiosGet(
		`${SERVER_ENDPOINT}/api/fields/getSubfields?field_id=${field_id}`
	);
};

export const updatePageField = async (field_data) => {
	return await axiosPut(
		`${SERVER_ENDPOINT}/api/fields/updateField`,
		field_data
	);
};

export const UpdateSubfield = async(data) => {
	return await axiosPut(
		`${SERVER_ENDPOINT}/api/fields/UpdateSubField`,
		data
	);
}

export const RemoveSubField = async(id) => {
	return await axiosDelete(
		`${SERVER_ENDPOINT}/api/fields/deleteSubField?id=${id}`,
	);
}

export const UploadFile = async (image_file) => {
    const data = new FormData();
    data.append("file", image_file);
    return await axiosPost(
        `${SERVER_ENDPOINT}/api/uploads/uploadFile`, 
        data, 
        'multipart/form-data', 
    )
}

export const getAllProjectsCats = async () => {
	return await axiosGet(
		`${SERVER_ENDPOINT}/api/categories/getAllCategories`
	);
};

export const createCategory = async (cat_data) => {
	return await axiosPost(
		`${SERVER_ENDPOINT}/api/categories/createCategory`,
		cat_data
	);
};

export const updateCategory = async (cat_data) => {
	return await axiosPut(
		`${SERVER_ENDPOINT}/api/categories/updateCategory`,
		cat_data
	);
};

export const deleteCategory = async (cat_id) => {
	return await axiosDelete(
		`${SERVER_ENDPOINT}/api/categories/deleteCategory?cat_id=${cat_id}`,
	);
};


export const getAllProjects = async (currentCategory) => {
	const url = currentCategory ? `${SERVER_ENDPOINT}/api/projects/getAllProjects?cat=${currentCategory}` : `${SERVER_ENDPOINT}/api/projects/getAllProjects`;
	return await axiosGet(url);
};

export const GetProjectBySlug = async (slug) => {
	return await axiosGet(
		`${SERVER_ENDPOINT}/api/projects/getProject?slug=${slug}`
	);
}

export const createProject = async (proj_data) => {
	return await axiosPost(
		`${SERVER_ENDPOINT}/api/projects/createProject`,
		proj_data
	);
};

// export const assignCategoriesToProject = async (data) => {
// 	return await axiosPost(
// 		`${SERVER_ENDPOINT}/api/projects/assignCategories`,
// 		data
// 	);
// };

export const CreateProjectGalleryItem = async (data) => {
	return await axiosPost(
		`${SERVER_ENDPOINT}/api/projects/assignGalleryItem`,
		data
	);
};

export const UpdateProjectData = async(data) => {
	return await axiosPut(
		`${SERVER_ENDPOINT}/api/projects/updateProject`,
		data
	);
}

export const UpdateGalleryImage = async(data) => {
	return await axiosPut(
		`${SERVER_ENDPOINT}/api/projects/updateGalleryImg`,
		data
	);
}

export const updateProjectOrder = async(data) => {
	return await axiosPut(
		`${SERVER_ENDPOINT}/api/projects/updateProjectOrder`,
		data
	);
}

export const updateRelatedProjects = async(data)=> {
	return await axiosPut(
		`${SERVER_ENDPOINT}/api/projects/setRelatedProjects`,
		data
	);
}

export const UpdateProjectVisibility = async(data)=> {
	return await axiosPut(
		`${SERVER_ENDPOINT}/api/projects/updateProjectVisibility`,
		data
	);
}

export const DeleteProjectImageFromGallery = async(img_id) => {
	return await axiosDelete(
		`${SERVER_ENDPOINT}/api/projects/DeleteProjectImage?id=${img_id}`,
	);
}
export const DeleteProject = async(project_id) => {
	return await axiosDelete(
		`${SERVER_ENDPOINT}/api/projects/deleteProject?id=${project_id}`,
	);
}

export const SendContactForm = async (data) => {
	return await axiosPost(
		`${SERVER_ENDPOINT}/api/sendForm/`,
		data
	);
};

export const cleanCache = async () => {
	return await axiosPost(
		`${SERVER_ENDPOINT}/api/sendForm/clearCache`,
		
	);
};
//