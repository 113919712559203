import styled, { css } from 'styled-components';

export const FakeLoaderContainer = styled.div`
	position: fixed;
    background: #383838;
    inset: 0;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    ${(props) =>
        props.visibility &&
        css`
          opacity: 1;
          visibility: visible;
        `
    }
`;
