import React, { useContext, useEffect, useState } from 'react'
import { ReactComponent as LogoutIcon } from '../../assets/imgs/Admin Panel/logout_FILL0_wght400_GRAD0_opsz24.svg';
import AppContext from '../../Contexts/AppContext';
import { ReactComponent as CacheIcon } from '../../assets/imgs/Admin Panel/cache.svg';
import { NavbarContainer } from '../../Styles/Admin Panel Styles/NavbarStyles';
import { ContainedButton, TransparentBtn } from '../../Styles/Admin Panel Styles/ButtonStyles';
import { Modal, ModalContainer } from '../../Styles/Admin Panel Styles/ModalStyles';
import { cleanCache, userLogout } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { alertPopup } from '../../services/alertPopup';

function AdminNav() {
  let navigate = useNavigate();
  const [scrolled, setScrolled] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {loggedInUser, setLoggedInUser} = useContext(AppContext);

  const handleScrollNav = () => {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      setScrolled('scrolled');
      return;
    } 
    setScrolled('');
  }

  const handleLogout = async() => {
    const res = await userLogout();
    if(res.success){
      setLoggedInUser({});   
      navigate('/login');     
    }           
  }

  const handleCleanCache = async() => {
      const res = await cleanCache();
      if(res.success){
        alertPopup("Cache successfully purged!", 'success');
      }
  }

  const handleOpenModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    window.onscroll = () => {handleScrollNav()};
  }, [])
      
    return (
      <>
        <NavbarContainer className={`flex justify-between align-center ${scrolled }`}>
                <h4>Howdy, {loggedInUser?.username}</h4>
                <div className='flex align-center' style={{gap: '2vw'}}>
                  <TransparentBtn onClick={handleCleanCache}><CacheIcon/> Clear Cache </TransparentBtn>
                  <TransparentBtn onClick={handleOpenModal}><LogoutIcon/> Logout </TransparentBtn>
                </div>
        </NavbarContainer> 
        <Modal
          className={isModalOpen ? 'open' : 'closed'}
          onClick={handleOpenModal}
        >
          <ModalContainer onClick={e => e.stopPropagation()}>
              <div className='scrollable flex-column'>
                <p className='flex justify-center'>Are you sure you want to logout?</p>
                <div className='btns flex justify-center align-center'>
                    <ContainedButton onClick={()=>handleLogout()} primary> LOGOUT </ContainedButton>
                    <ContainedButton secondary onClick={handleOpenModal}> CANCEL </ContainedButton>
                </div>
              </div>
          </ModalContainer>
        </Modal>
      </>
    )
}

export default AdminNav