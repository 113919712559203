import styled, { css } from 'styled-components';

export const CategoriesStyle = styled.div`
	margin-bottom: min(104px, 12vw);

	.swiper-slide {
		background: linear-gradient(
				127.27deg,
				${({ theme }) => theme.isDark ? theme.gray[500] : theme.gray[800]} 1.18%,
				${({ theme }) => theme.isDark ? 'rgba(65, 65, 65, 0.12)' : 'rgba(213, 212, 255, 0.12)'} 102.7%
			),
			${({ theme }) => theme.gray[700]};

		box-shadow: -5.1px -5.1px 10.2px ${({ theme }) => theme.shadow[100]},
		5.1px 5.1px 14px ${({ theme }) => theme.shadow[850]},
		inset 0px 4.08px 42.84px ${({ theme }) => theme.shadow[800]};
		text-align: center;
		padding-block: 12px;
		border-radius: 25px;

		&.selected {
			position: relative;
			color: ${({ theme }) => theme.primary_darker};
			text-shadow: -1px 1px 30px rgba(161, 200, 58, 0.51);
			font-weight: 700;
		}
		/* 
		@media only screen and (max-width: 550px) {
			width: 57% !important;
		} */
	}
`;

export const CategoryTitle = styled.div`
	font-size: clamp(16px, 2vw, 20px);
	line-height: 1.2;
	cursor: pointer;

	&:hover{
		color: ${({ theme }) => theme.primary_darker};
	}

	&.selected {
		position: relative;
		color: ${({ theme }) => theme.primary_darker};
		text-shadow: -1px 1px 30px rgba(161, 200, 58, 0.51);
		font-weight: 700;

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			left: 0;
			bottom: -16px;
			border-bottom: 1px ${({ theme }) => theme.primary_darker} dashed;
			box-shadow: -1px 1px 30px rgba(161, 200, 58, 0.51);
		}
	}
`;

export const Grid = styled.div`
	margin-bottom: min(112px, 12vw);
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: auto;
	grid-column-gap: min(63px, 8vw);
	grid-row-gap: min(63px, 8vw);

	@media only screen and (max-width: 728px) {
		grid-template-columns: 1fr;
	}
`;

export const GridCardDiv = styled.div`
	height: 100%;
	background: ${({ theme }) => theme.gradient[400]};
	box-shadow: -5.1px -5.1px 10.2px ${({ theme }) => theme.shadow[100]},
		5.1px 5.1px 14px ${({ theme }) => theme.shadow[850]},
		inset 0px 4.08px 42.84px ${({ theme }) => theme.shadow[800]};
	border-radius: min(25px, 2vw);

	h3 {
		font-family: 'Poppins';
		font-weight: 700;
		font-size: 16px;
		line-height: 1.2;
		text-align: center;
	}

	p {
		font-family: 'Poppins';
		font-weight: 700;
		font-size: 14px;
		line-height: 1.5;
		letter-spacing: 0.05em;
		text-align: center;
		color: ${({ theme }) => theme.gray[200]};
	}

	span.react-resizable-handle {
		display: none;
	}
`;

export const RespGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;

	.single-project-img, .project {
		grid-column: span 1;

		&.single-project-img{
			aspect-ratio: 1;
		}
	} 

	.single-project-img:nth-child(5n + 1),
	.project:nth-child(5n + 1) {
		grid-column: span 2;
		aspect-ratio: 2/1;

		&.project{
			aspect-ratio: 2/1.5;
			
			.image-wrapper{
				height: 60%;
			}
	
			.text-container{
				height: 40%;
			}
		}
	}
`;
