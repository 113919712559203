import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../Contexts/AppContext';
import { DeleteProject } from '../../services/api';
import { ContainedButton } from '../../Styles/Admin Panel Styles/ButtonStyles';
import Loader from './Loader';
import { alertPopup } from '../../services/alertPopup';

function DeleteProjectLayout({ handleOpenModal }) {
	const navigate = useNavigate();

	const { currentProject, setCurrentProject } = useContext(AppContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleDeleteProject = async () => {
		if (!currentProject.id) return alertPopup('error occurred', 'error');
		setIsLoading(true);
		const res = await DeleteProject(currentProject.id);
		if (res.success) {
			setIsLoading(false);
			navigate('/projects');
		}

		//redirect to projects
	};

	return (
		<div>
			{isLoading && <Loader />}
			{!isLoading && (
				<>
					<h3
						className='flex justify-center'
						style={{ marginBlock: 'max(30px, 5vw)' }}
					>
						Are you sure you want to delete project "
						{currentProject.project_title}" ?
					</h3>
					<div className='btns flex justify-center align-center'>
						<ContainedButton
							onClick={() => handleDeleteProject()}
							primary
						>
							{' '}
							DELETE{' '}
						</ContainedButton>
						<ContainedButton secondary onClick={handleOpenModal}>
							{' '}
							CANCEL{' '}
						</ContainedButton>
					</div>
				</>
			)}
		</div>
	);
}

export default DeleteProjectLayout;
