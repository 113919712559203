import './assets/scss/general.scss';
import { useEffect, useCallback , useRef, useState, Suspense, lazy } from 'react';
import {
	Routes,
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';
import Sidebar from './Components/Sidebar';
import ThemeSwitcher from './Components/ThemeSwitcher';
import Projects from './Screens/Projects';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './Styles/GlobalStyles';
import { darkTheme, lightTheme } from './Styles/CustomTheme';
import SingleProject from './Screens/SingleProject';
import Footer from './Components/Footer';
import Logo from './Components/Logo';
import AppContext from './Contexts/AppContext';
import {
	fetchVerifiedUser,
	getAllProjectsCats,
	getPageFields,
} from './services/api';
import { ToastContainer, toast } from 'react-toastify';
import ScrollToTop from './Components/ScrollToTop';
import MobileHeader from './Components/MobileHeader';
import Loader from './Components/Admin Panel/Loader';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import FakeLoader from './Components/FakeLoader';
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import LazyHydrate from "react-lazy-hydration";
import NotFound from './Screens/NotFound';

const Login = lazy(() => import('./Screens/Login'));
const About = lazy(() => import('./Screens/About'));
const Home = lazy(() => import('./Screens/Home'));
const ThankYou = lazy(() => import('./Screens/ThankYou'));
const AccessibilityStatement = lazy(() => import('./Screens/AccessibilityStatement'));

function App() {

	const [loggedInUser, setLoggedInUser] = useState({});
	const [isDarkTheme, setIsDarkTheme] = useState(true);
	const [pageFields, setPageFields] = useState({});
	const [globalFields, setGlobalFields] = useState({});
	const [projects, setProjects] = useState([]);
	const [projectsCategories, setProjectsCategories] = useState([]);
	const [currentProject, setCurrentProject] = useState({});
	const [windowLocation, setWindowLocation] = useState(
		window.location.pathname
	);
	const [isLoading, setIsLoading] = useState(true);
	const screenSize = useRef(window.innerWidth);

	const handleChangeTheme = () => {
		setIsDarkTheme(!isDarkTheme);
		localStorage.setItem('themeColor', !isDarkTheme ? 'dark' : 'light');
	};

	const verifyUserSessionIsActive = async () => {
		try {
			const verifiedUser = await fetchVerifiedUser();
			if (verifiedUser.success && verifiedUser.data.email) {
				setLoggedInUser({ ...verifiedUser.data });
				return;
			} else if (verifiedUser.status !== 200) {
			}
			setLoggedInUser({});
		} catch (error) {
			console.log('error:', error);
		}
	};

	const handleGetFields = async (page_id) => {
		const res = await getPageFields(page_id);
		if (res.success && res.data) {
			page_id === 0 ? setGlobalFields(res.data) : setPageFields(res.data);
		}
	};

	const handleGetProjectsCats = async () => {
		const res = await getAllProjectsCats();
		if (res.success && res.data) {
			setProjectsCategories(res.data);
			setIsLoading(false);
		}
	};

	const appContextData = {
		loggedInUser,
		setLoggedInUser,
		isDarkTheme,
		handleChangeTheme,
		windowLocation,
		setWindowLocation,
		pageFields,
		setPageFields,
		handleGetFields,
		globalFields,
		setGlobalFields,
		projects,
		setProjects,
		projectsCategories,
		setProjectsCategories,
		currentProject,
		setCurrentProject,
		toast,
		screenSize,
	};

	const particlesInit = useCallback(async (engine) => {
		await loadSlim(engine);
	}, []);

	const particlesLoaded = useCallback(async (container) => {
		// await console.log(container);
	}, []);

	useEffect(() => {
		verifyUserSessionIsActive();
		handleGetFields(0); //for footer and contact
		handleGetProjectsCats();
		localStorage.getItem('themeColor') &&
			(localStorage.getItem('themeColor') === 'light' && setIsDarkTheme(false));
	}, []);

	useEffect(() => {
		setWindowLocation(window.location.pathname)
		const handleResize = () => {
			screenSize.current = window.innerWidth;
		};
		window.addEventListener('resize', handleResize);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [window.location.pathname]);

	return (
		<LazyHydrate whenVisible>
			<ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
				<SkeletonTheme
					baseColor={`${isDarkTheme ? '#404040' : 'fcfcfc'}`}
					highlightColor={`${isDarkTheme ? '#444' : '#fff'}`}
				>
					<AppContext.Provider value={appContextData}>
						<Particles
							id="tsparticles"
							init={particlesInit}
							loaded={particlesLoaded}
							options={{
								interactivity: {
									events: {
										onClick: {
											enable: true,
											mode: "push",
										},
										onHover: {
											enable: true,
											mode: "repulse",
										},
										resize: true,
									},
									modes: {
										push: {
											quantity: 4,
										},
										repulse: {
											distance: 150,
											duration: 0.8,
										},
									},
								},
								particles: {
									color: {
										value: "rgba(162,200,58,0.8)",
									},
									links: {
										color: "rgba(162,200,58,0.8)",
										distance: 13,
										enable: true,
										opacity: 0.5,
										width: 1,
									},
									move: {
										direction: "none",
										enable: true,
										outModes: {
											default: "bounce",
										},
										random: false,
										speed: 0.7,
										straight: false,
									},
									number: {
										density: {
											enable: true,
											area: 1500,
										},
										value: screenSize.current > 768 ? 100 : 70,
									},
									opacity: {
										value: 0.5,
									},
									shape: {
										type: "circle",
									},
									size: {
										value: { min: 1, max: 3 },
									},
								},
								detectRetina: true,
							}}
						/>
						<GlobalStyles />
						<ToastContainer />
						<FakeLoader visibility={isLoading}/>
						<Router>
							<div className={`relative ${loggedInUser?.username && 'logged'}`} style={{minHeight: '100vh'}}>
								<ScrollToTop />
								{screenSize.current <= 768 ? (
									<MobileHeader isDarkTheme={isDarkTheme
									}/>
								) : (
									<>
										<Sidebar />
										{windowLocation !== '/login' && 
											<>
												<ThemeSwitcher />
												<Logo />
											</>
										}
									</>
								)}
								{/* <div style={{overflowX: 'hidden'}}> */}
									<Routes>
										<Route
											exact
											path={'/'}
											element={
												<Suspense fallback={<Loader />}>
													<Home />
												</Suspense>
											}
											errorElement={<NotFound />}
										></Route>
										<Route
											exact
											path={'/about'}
											element={
												<Suspense fallback={<Loader />}>
													<About />
												</Suspense>
											}
											errorElement={<NotFound />}
										></Route>
										<Route
											exact
											path={'/login'}
											element={
												<Suspense fallback={<Loader />}>
													<Login />
												</Suspense>
											}
											errorElement={<NotFound />}
										></Route>
										<Route
											exact
											path={'/projects'}
											element={
												<Suspense fallback={<Loader />}>
													<Projects />
												</Suspense>
											}
											errorElement={<NotFound />}
										></Route>
										<Route
											exact
											path={'/projects/:projectname'}
											element={
												<Suspense fallback={<Loader />}>
													<SingleProject />
												</Suspense>
											}
											errorElement={<NotFound />}
										></Route>
										<Route
											exact
											path={'/thank-you'}
											element={
												<Suspense fallback={<Loader />}>
													<ThankYou />
												</Suspense>
											}
											errorElement={<NotFound />}
										></Route>
										<Route
											exact
											path={'/accessibility-statement'}
											element={
												<Suspense fallback={<Loader />}>
													<AccessibilityStatement />
												</Suspense>
											}
											errorElement={<NotFound />}
										></Route>
										{/* for 404 */}
										<Route path='*' element={<NotFound />} />
									</Routes>
								{/* </div> */}
							</div>
							{windowLocation !== '/login' && <Footer/>}
						</Router>
					</AppContext.Provider>
				</SkeletonTheme>
			</ThemeProvider>
		</LazyHydrate>
	);
}

export default App;
