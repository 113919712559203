import styled from 'styled-components';

export const LoaderContainer = styled.div`
	transform: scale(0.8);
	svg.loader-animated {
		path {
			stroke: ${({ theme }) => theme.primary};
			fill: transparent;
			stroke-dasharray: 700;
			stroke-dashoffset: -700;
			animation: animate 8s both infinite;
		}
	}
`;

export const HeroAnimationContainer = styled.div`
	left: 50%;
	top: -50%;
	transform: scale(3.5) translateX(-50%);
	transform-origin: left;
	z-index: -1;

	svg{
		stroke-width: 0.5px;

		path{
			stroke-dasharray: 453;
        	stroke-dashoffset: 453;
			animation: animateTest 3s 1s both !important;
		
			@media only screen and (max-width: 768px) {
				animation-delay: 0s;
			}
		}
	}

	@media only screen and (max-width: 1024px) {
		transform: scale(2.3) translateX(-50%);
		top: -100%;
	}

	@media only screen and (max-width: 575px) {
		transform: scale(1.8) translateX(-50%);
		top: -100%;
	}
`
