import React, { useEffect, useState } from 'react';
import { CardStyle } from '../Styles/GeneralStyles';
import { SERVER_ENDPOINT } from '../services';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Card({ image, title, desc, paddingVal, backImage = '' }) {
	return (
		<CardStyle className={`project`} padding={paddingVal}>
			<div className='image-wrapper'>
				<LazyLoadImage 
					src={
						image?.file_url
							? `${SERVER_ENDPOINT + image?.file_url}`
							: `${SERVER_ENDPOINT + image?.img_url}`
					}
					alt='Site main page'
				/>
				<LazyLoadImage 
					src={
						backImage?.file_url
							? `${SERVER_ENDPOINT + backImage?.file_url}`
							: `${SERVER_ENDPOINT + backImage?.img_url}`
					}
					alt='employee'
				/>
			</div>
			<h3>{title}</h3>
			<p>{desc}</p>
		</CardStyle>
	);
}

export default Card;
