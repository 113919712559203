import styled, { css } from 'styled-components';

export const HeaderContainer = styled.header`
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: max(1vw, 12px) max(1.4vw, 18px);
    z-index: 9;

    &.scroll-to-bottom{
        top: -70px;
    }

    &.scroll-to-top{
        top: 0;
        background: ${({ theme }) => theme.gray[700]};
        box-shadow: 2px 2px 4px ${({ theme }) => theme.shadow[600]};
    }

    &.scroll-to-top.menu-open{
       box-shadow: box-shadow: 2px 2px 4px transparent;
    }
`;

export const NavWrapper = styled.ul`
	padding: 0;
    margin: 0;
`;

export const NavItem = styled.li`
	max-width: 33%;

    svg.light{
        path{
            fill: ${({ theme }) => theme.gray[100]};
        }
    }
`;

export const Hamburger = styled.div`
    width: 45px;
    height: 45px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: all .5s ease;
    *{transition: all .5s ease;}
    background: ${({ theme }) => theme.gray[700]};
    box-shadow: 2px 2px 4px ${({ theme }) => theme.shadow[600]}, -2px -2px 4px ${({ theme }) => theme.shadow[500]};
    border-radius: 8px;

    .strip div {
        height: 2px;
        border-radius: 2px;
        background: ${({ theme }) => theme.gray[100]};
        margin: 8px;
        width: 30px;
        transition: all .65s cubic-bezier(0.075, 0.82, 0.165, 1)
    }

    &.open{
        border-radius: 8px 8px 0 0;
        box-shadow: 2px -4px 4px ${({ theme }) => theme.shadow[600]}, -2px -4px 4px ${({ theme }) => theme.shadow[500]};

        .burger-strip{
            transform: scale(0.8) rotate(45deg);
            transition: all .25s ease .05s;

            div:first-child{
                transform: translateY(10px) rotate(0deg) ;
            }
    
            div:nth-child(2) {
                opacity: 0;
            }
    
            div:last-child {
                transform: translateY(-10px) rotate(90deg) ;
            }
        }
        
    }
`;

export const MobileMenuContainer = styled.div`
    position: fixed;
    width: 100%;
    top: 56px;
    right: -100%;
    height: calc(100vh - 56px);
    background: ${({ theme }) => theme.gray[700]};
    color: ${({ theme }) => theme.gray[300]};
    z-index: 9;
    box-shadow: 4px 4px 10px ${({ theme }) => theme.shadow[600]}, -4px -4px 10px ${({ theme }) => theme.shadow[500]};

    &.open{
        right: 0;

    }

    .mobile-menu-wrapper{
        gap: 5vh;
        padding-block: 5vh;
    }
`