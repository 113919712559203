import React, { useRef, useEffect, useState, useContext } from 'react';
import { SERVER_ENDPOINT } from '../services';
import { CardStyle } from '../Styles/GeneralStyles';
import placeholder_img from '../assets/imgs/contact-form-decoration.png';
import { Link } from 'react-router-dom';
import Loader from './Admin Panel/Loader';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AppContext from '../Contexts/AppContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function ProjectCard({ project_data, paddingVal, staticHeight, index }) {
	const {screenSize} = useContext(AppContext)
	const [project, setProject] = useState(project_data ? project_data : {});
	const [projectMainImg, setProjectMainImg] = useState(
		project_data?.gallery ? project_data?.gallery?.find((img) => img.is_main) : {},
	);

	useEffect(() => {
		if(Object.keys(project_data).length > 0){
			setProject(project_data);
			setProjectMainImg(project_data?.gallery ? project_data?.gallery?.find((img) => img.is_main) : {});
		}
	}, [project_data])

	useEffect(() => {
        AOS.init();
    }, [])
	
	return (
		<CardStyle
			className={staticHeight && 'related-project'}
			padding={paddingVal}
			isProject={true}
			data-aos="fade-up"
			data-aos-duration={400}
			data-aos-delay={index*100}
		>
			{Object.keys(project).length > 0 && (
				<Link to={`/projects/${project?.project_slug}`} reloadDocument>
					<div
						className={
							project?.order?.h === 1 || staticHeight
								? 'image-wrapper height-short'
								: 'image-wrapper height-long'
						}
					>
						{projectMainImg ? (
							<LazyLoadImage
								alt={project?.project_title}
								src={`${
									SERVER_ENDPOINT + projectMainImg?.img_url
								}`}
							/>
						) : (
							<LazyLoadImage
								alt='theguy placeholder'
								src={placeholder_img}
							/>
						)}
					</div>
					<div
						className={
							project?.order?.h === 1 || staticHeight
								? 'text-container height-short'
								: 'text-container height-long'
						}
					>
						<h3>{project?.project_title}</h3>
						<p>
						{project?.project_subtitle.split(' ').length > (screenSize.current > 768 ? 11 : 7)
							? `${project?.project_subtitle
								.split(' ')
								.slice(0, screenSize.current > 768 ? 11 : 7)
								.join(' ')} ...`
							: project?.project_subtitle
						}

						</p>
					</div>
				</Link>
			)}
			{Object.keys(project).length === 0 && <Loader />}
		</CardStyle>

	);
}

export default ProjectCard;
