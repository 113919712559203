import styled, { css } from 'styled-components';

export const Input = styled.input`
    background: ${({ theme }) => theme.gray[700]};
    box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[300]}, 
    -1px -1px 2px ${({ theme }) => theme.shadow[600]}, 
    inset -3px 3px 6px ${({ theme }) => theme.shadow[500]}, 
    inset -3px -3px 6px ${({ theme }) => theme.shadow[400]}, 
    inset 3px 3px 8px ${({ theme }) => theme.shadow[700]};
    border-radius: max(12px, 0.8vw);
    border: none;
    outline: none;
    padding: 8px 18px;
    color: ${({ theme }) => theme.gray[100]};
    font-size: max(16px, 1.1vw);
    ${(props) =>
		props.width ?
		css`
      width: ${(props) => props.width};
		`
    : 
    css`
      width: 100%;
		`
    }
    ${(props) =>
      props.light &&
      css`
        background: ${({ theme }) => theme.gray[500]};
        box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[100]}, 
        -1px -1px 2px ${({ theme }) => theme.shadow[500]}, 
        inset -3px 3px 6px ${({ theme }) => theme.shadow[400]}, 
        inset -3px -3px 6px ${({ theme }) => theme.shadow[300]}, 
        inset 3px 3px 8px ${({ theme }) => theme.shadow[600]};
      `
    }
    ${(props) =>
      props.sm &&
      css`
        font-size: max(16px, 0.9vw);
        font-weight: 300;
      `
    }
    &.upload-file-input{
      background: unset;
      box-shadow: unset;
      border-radius: unset;
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    
`;

export const InputWrapper = styled.div`
    gap: max(12px, 2vw);

    svg.max{
        transform: scale(1.3);
    }

    ${(props) =>
		props.rounded &&
		css`
            padding: 2vw;
            background: ${({ theme }) => theme.gray[700]};
            border-radius: 0 0 1.3vw 1.3vw;
		` 
    };
    ${(props) =>
      props.width &&
      css`
        width: ${(props) => props.width};
      `
    }

`;

export const Textarea = styled.textarea`
    background: ${({ theme }) => theme.gray[700]};
    box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[300]}, 
    -1px -1px 2px ${({ theme }) => theme.shadow[600]}, 
    inset -3px 3px 6px ${({ theme }) => theme.shadow[500]}, 
    inset -3px -3px 6px ${({ theme }) => theme.shadow[400]}, 
    inset 3px 3px 8px ${({ theme }) => theme.shadow[700]};
    border-radius: max(12px, 0.8vw);
    border: none;
    outline: none;
    padding: 18px;
    color: ${({ theme }) => theme.gray[100]};
    font-size: max(16px, 0.9vw);
    font-weight: 300;
    width: 100%;
    resize: none;
    ${(props) =>
      props.light &&
      css`
        background: ${({ theme }) => theme.gray[500]};
        box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[100]}, 
        -1px -1px 2px ${({ theme }) => theme.shadow[500]}, 
        inset -3px 3px 6px ${({ theme }) => theme.shadow[400]}, 
        inset -3px -3px 6px ${({ theme }) => theme.shadow[300]}, 
        inset 3px 3px 8px ${({ theme }) => theme.shadow[600]};
      `
    }
`;