import styled, { css } from 'styled-components';

export const ProjectsLayoutContainer = styled.div`
    width: max(300px, 35vw);
    height: 78vh;
    padding: max(30px, 1.8vw);
    .layout{
        min-height: 90%;
    }

`