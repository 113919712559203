import styled, { css } from 'styled-components';

export const LoginButtonStyle = styled.button`
	position: relative;
	top: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	padding: 10px 22px;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: max(12px, 0.8vw);
	font-weight: 300;
	font-size: max(18px, 1vw);
	color: #fcfcfc;
	background: ${({ theme }) => theme.gray[700]};
	box-shadow: 2px 2px 8px ${({ theme }) => theme.shadow[700]},
		-2px -2px 8px ${({ theme }) => theme.shadow[100]};
	width: 100%;

	&:hover {
		a {
			color: rgba(135, 166, 50, 1);
		}
	}

	&:active {
		top: 5px;
		// box-shadow:  inset 2px 2px 8px ${({ theme }) => theme.shadow[700]},
		//     inset -2px -2px 8px ${({ theme }) => theme.shadow[100]};
	}
`;
