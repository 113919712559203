import React, { useContext, useEffect } from 'react';
import { ThemeSwitcherStyle } from '../Styles/GeneralStyles';
import AppContext from '../Contexts/AppContext';
import { ReactComponent as SunIcon } from '../assets/imgs/theme-switcher-sun.svg';
import { ReactComponent as MoonIcon } from '../assets/imgs/theme-switcher-moon.svg';

function ThemeSwitcher() {
	const { handleChangeTheme, isDarkTheme, screenSize, windowLocation } =
		useContext(AppContext);

	return (
		<label htmlFor='themeSwitcher'>
			<ThemeSwitcherStyle>
				<input
					type='checkbox'
					id='themeSwitcher'
					name='themeSwitcher'
					onClick={() => {
						handleChangeTheme();
					}}
				></input>
				{screenSize.current > 1400 && (
					<>
						<span className='light-title'>light</span>
						<span className='dark-title'>dark</span>
					</>
				)}
				<div className='sun-wrapper'>
					{isDarkTheme ? <SunIcon /> : <MoonIcon />}
				</div>
			</ThemeSwitcherStyle>
		</label>
	);
}

export default ThemeSwitcher;
