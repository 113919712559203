import styled, { css } from 'styled-components';

export const GridBlock = styled.div`
	border-radius: max(1.302vw, 10px);
	overflow: hidden;
	box-shadow: -5.1px -5.1px 10.2px ${({ theme }) => theme.shadow[100]},
		5.1px 5.1px 14px ${({ theme }) => theme.shadow[850]},
		inset 0px 4.08px 42.84px ${({ theme }) => theme.shadow[800]};


	img {
		object-fit: cover;
		object-position: center;
		width: 100%;
		height: 100%;
	}
`;
