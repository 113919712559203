import React, { useContext, useEffect, useState } from 'react'
import { ContainedButton, EditBtn } from '../../Styles/Admin Panel Styles/ButtonStyles';
import { ReactComponent as EditIcon } from '../../assets/imgs/Admin Panel/edit_FILL0_wght400_GRAD0_opsz24.svg';
import { Modal, ModalContainer } from '../../Styles/Admin Panel Styles/ModalStyles';
import Select from 'react-select';
import { getAllProjects, updateRelatedProjects } from '../../services/api';
import AppContext from '../../Contexts/AppContext';
import { CustomSelectStyles } from '../../Styles/Admin Panel Styles/SelectStyles';

function EditRelatedProjects({currentProject, setRelatedProjects}) {
    const {projects} = useContext(AppContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(currentProject?.related_projects?.map(proj=>{
        return {value: proj.id, label: proj.project_title}
    }) || []);
    const [options, setOptions] = useState([])

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen)
    };

    const handleGetProjects = async () => {
		const res = await getAllProjects();
		if (res.success && res.data.length > 0) {
            const projects_without_curent = res.data.filter(proj=> +proj.id !== +currentProject.id);
            if(projects_without_curent){
                setOptions(projects_without_curent.map((proj) => {
                    return {value: proj.id, label: proj.project_title}
                }));
            }
		}
	};

    const handleUpdateRelatedProjects = async() => {
        const data = {
            projectId: currentProject.id,
            relatedProjects: selectedOptions.map(opt=> {return opt.value})
        }
        const res = await updateRelatedProjects(data);
        if(res.success && res.data) {
            setRelatedProjects(res.data);
            handleOpenModal();
        } else {
            alert('error');
        }
    }

    useEffect(()=> {
        handleGetProjects();
        setSelectedOptions(currentProject?.related_projects?.map(proj=>{
            return {value: proj.id, label: proj.project_title}
        }) || [])

    }, [currentProject])

    return (
        <>
            <EditBtn 
                onClick={handleOpenModal} 
            >
                <EditIcon/>
            </EditBtn>
            {isModalOpen && 
                <Modal
                    className={ isModalOpen ? 'related-modal open' : 'closed'}
                    onClick={handleOpenModal}
                >
                    <ModalContainer onClick={e => e.stopPropagation()} dark={true}>
                        <div className='scrollable flex-column relative'>
                            <h3 className="title flex justify-center">RELATED PROJECTS</h3>
                            <div className="related-projects">
                                <h6 className="sub">You can only choose 3</h6>
                                <Select
                                    styles={CustomSelectStyles}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    defaultValue={selectedOptions}
                                    onChange={(selected) => setSelectedOptions(selected)}
                                    isOptionDisabled={() => selectedOptions.length >= 3}
                                    options={options}
                                    isMulti={true}
                                />
                            </div>
                            <div className='btns flex justify-center align-center'>
                                <ContainedButton onClick={()=>handleUpdateRelatedProjects()} primary> SAVE </ContainedButton>
                                <ContainedButton secondary onClick={handleOpenModal}> CANCEL </ContainedButton>
                            </div>
                        </div>
                    </ModalContainer>
                </Modal>
            }
        </>
    )
}

export default EditRelatedProjects