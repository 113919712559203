import React from 'react'
import { FakeLoaderContainer } from '../Styles/FakeLoaderStyles'
import Loader from './Admin Panel/Loader'

function FakeLoader({visibility}) {
  return (
    <FakeLoaderContainer className='flex align-center justify-center' visibility={visibility}>
        <Loader/>
    </FakeLoaderContainer>
  )
}

export default FakeLoader