import Axios from 'axios';

// export const SERVER_ENDPOINT = 'http://localhost:3016';
export const SERVER_ENDPOINT = 'https://app.theguy.co.il';

export const axiosGet = async (url) => {
	const res = await Axios.get(url, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Access-Control-Allow-Origin':
				'https://app.theguy.co.il',
		},
	});
	return res.data;
};

export const axiosPost = async (url, data, type='application/json') => {
	const res = await Axios.post(url, data, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Access-Control-Allow-Origin':
				'https://app.theguy.co.il',
			'Content-Type': type,
		},
	});
	return res.data;
};

export const axiosPut = async (url, data, type='application/json') => {
	const res = await Axios.put(url, data, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Access-Control-Allow-Origin':
				'https://app.theguy.co.il',
			'Content-Type': type,
		},
	});
	return res.data;
};

export const axiosDelete = async (url) => {
	const res = await Axios.delete(url, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Access-Control-Allow-Origin':
				'https://app.theguy.co.il',
		},
	});
	return res.data;
};