import React, { useState } from 'react'
import { createCategory, deleteCategory, updateCategory } from '../../services/api';
import { ContainedButton, EditBtn } from '../../Styles/Admin Panel Styles/ButtonStyles';
import { Input, InputWrapper } from '../../Styles/Admin Panel Styles/InputStyles'
import { ReactComponent as DeleteIcon } from '../../assets/imgs/Admin Panel/delete_forever_FILL0_wght400_GRAD0_opsz24.svg';
import { UploadContainer } from '../../Styles/Admin Panel Styles/Containers';


function CategoriesEdit({
    projectsCategories,
    setProjectsCategories,
    handleOpenModal
}) {
    const [newCat, setNewCat] = useState('');
    const [catsToUpdate, setCatsToUpdate] = useState([]);
    const [isUploadVisible, setIsUploadVisible] = useState(false);

    const handleUpdateCategories = async () => {
        if(newCat.length > 0){
            const res = await createCategory({category_name: newCat});
            if (res.success && res.data){
                setProjectsCategories([...projectsCategories, res.data])
            }

        }

        for (let i = 0; i < catsToUpdate.length; i++) {
            const res = await updateCategory(catsToUpdate[i]);
            if (res.success && res.data){
                const prevCats = [...projectsCategories];
                const updatedCatIndex = prevCats.findIndex(cat => +cat.id === +res.data?.id);
                if (updatedCatIndex !== -1) {
                    prevCats[updatedCatIndex] = res.data;
                    setProjectsCategories(prevCats);
                }
            }
        }
        setNewCat('');
        handleOpenModal();
    }
    
    const handleSaveUpdatedCat = (e) => {
        let cats = [...catsToUpdate];
        let founded_cat = cats?.find(cat => cat.id === e.target.id);
        if(founded_cat) {
            founded_cat.category_name = e.target.value;
            setCatsToUpdate(cats);
            return
        }

        let cat_to_update = {
            id: e.target.id,
            category_name: e.target.value
        }

        setCatsToUpdate([...cats, cat_to_update])
    }

    const handleDeleteCategory = async(cat_id) => {
        const res = await deleteCategory(cat_id);
        if(res.success){
            const prev_cats = [...projectsCategories];
            const updated_cats = prev_cats.filter(cat=> cat.id !== cat_id);
            setProjectsCategories(updated_cats);
        }
    }

    return (

        <div className ={'flex-column'} style={{gap: '3vh'}}>
            {projectsCategories.length > 0 && projectsCategories.map(cat => (
                <InputWrapper key={cat.id} className='flex justify-between'>
                    <Input 
                        defaultValue={cat?.category_name} 
                        id={cat.id} 
                        key={cat.id}
                        width={'70%'}
                        light
                        onChange={(e) => handleSaveUpdatedCat(e)}
                    />
                    <EditBtn onClick={()=>handleDeleteCategory(cat.id)} relative light> <DeleteIcon/> </EditBtn>
                </InputWrapper>
            ))}
            <UploadContainer className='flex-column align-center'>
                <ContainedButton
                    variant="contained" 
                    className={isUploadVisible && 'bordered'}
                    onClick={()=>setIsUploadVisible(!isUploadVisible)}
                    secondary
                    fullWidth
                >
                    ADD NEW CATEGORY
                </ContainedButton>
                <div className={`dropzone-container ${isUploadVisible ? 'visible' : 'hidden'}`}>
                <InputWrapper rounded>
                    <Input 
                        placeholder='Type Category Name'
                        value={newCat}
                        onChange={(e)=>setNewCat(e.target.value)}
                    />
                </InputWrapper>
                </div>
            </UploadContainer>
            <div className='btns flex justify-center align-center'>
                <ContainedButton  primary onClick={handleUpdateCategories}> SAVE </ContainedButton>
                <ContainedButton secondary onClick={handleOpenModal}> CANCEL </ContainedButton>
            </div>
        </div>
    )
}

export default CategoriesEdit