import React from 'react';
import { LoginButtonStyle } from '../Styles/Login Page Style/LoginButtonStyle';
import { NotFoundContainer } from '../Styles/Not Found Style/NotFoundContainer';
import { NotFoundWrapper } from '../Styles/Not Found Style/NotFoundContainer';
import { Link } from 'react-router-dom';

function NotFound() {
	return (
		<NotFoundContainer className='flex justify-center align-center'>
			<NotFoundWrapper>
				<h1>Oops!</h1>
				
				<div>404 Page Not Found</div>
				<div>We Can't Find The Page You Are Looking For...</div>
				<LoginButtonStyle>
					<Link to='/'>Go Back Home</Link>
				</LoginButtonStyle>
			</NotFoundWrapper>
		</NotFoundContainer>
	);
}

export default NotFound;
