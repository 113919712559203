import React, { useState } from 'react'
import { EditBtn } from '../../Styles/Admin Panel Styles/ButtonStyles'
import { Modal, ModalContainer } from '../../Styles/Admin Panel Styles/ModalStyles';
import { ReactComponent as EditIcon } from '../../assets/imgs/Admin Panel/edit_FILL0_wght400_GRAD0_opsz24.svg';
import CategoriesEdit from './CategoriesEdit';

function EditCatsBtn({projectsCategories, setProjectsCategories}) {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen)
    };

    return (
        <>
            <EditBtn onClick={handleOpenModal}>
                <EditIcon/>
            </EditBtn>
            <Modal
                className={isModalOpen ? 'open' : 'closed'}
                onClick={handleOpenModal}
            >
                <ModalContainer onClick={e => e.stopPropagation()}>
                    <div className='scrollable flex-column relative'>
                        <CategoriesEdit
                            projectsCategories={projectsCategories}
                            setProjectsCategories={setProjectsCategories}
                            handleOpenModal={handleOpenModal} 
                        />
                    </div>
                </ModalContainer>
            </Modal>
        </>
    )
}

export default EditCatsBtn