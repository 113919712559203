import styled, { css } from 'styled-components';

export const Modal = styled.div`
    position: fixed;
    inset: 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.5);

    &.open{
        opacity: 1;
        visibility: visible;
    }
`;

export const ModalContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: max(40%, 300px);
    max-width: 95vw;
    overflow: hidden;
    z-index: 999;
    background: ${({ theme }) => theme.gray[500]};
    padding: 1.8vw;
    border-radius: 26px;
    box-shadow:  -5.1px -5.1px 10.2px ${({ theme }) => theme.shadow[100]},
    5.1px 5.1px 10.2px ${({ theme }) => theme.shadow[850]},
    inset 0px 4.08px 42.84px ${({ theme }) => theme.shadow[800]};
    .btns{
        gap: 3vw;
    }
    .scrollable{
        padding: 10px;
        padding-inline-end: 20px;
        gap: 2vw;
        max-height: 80vh;
        overflow-x: hidden;
        overflow-y:auto;
    }

    .abs-close{
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        right: auto;
        transform: unset;
    }

    ${(props) =>
		props.dark &&
		css`
            background: ${({ theme }) => theme.gray[700]};
            padding: 1.8vw;
            border-radius: 26px;
            box-shadow:  -5.1px -5.1px 10.2px ${({ theme }) => theme.shadow[500]},
            5.1px 5.1px 10.2px ${({ theme }) => theme.shadow[900]},
            inset 0px 4.08px 42.84px ${({ theme }) => theme.shadow[850]};
		`
    }
`;