import styled, { css } from 'styled-components';

export const UploadContainer = styled.div`
    .dropzone-container{
        max-height: 0;
        overflow: hidden;
        width: 100%;
        transition: max-height ease-out 300ms;
    
        &.visible{
            max-height: 300px;
        }

        .dropzone-ui{
            overflow: hidden;
            height: 300px;
            min-height: 300px;
            border: none;
            border-radius: 0 0 1.3vw 1.3vw;
            width: 100%;
            color: ${({ theme }) => theme.gray[100]};
            background: ${({ theme }) => theme.gray[700]};
            box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[300]}, -1px -1px 2px ${({ theme }) => theme.shadow[600]}, inset -3px 3px 6px ${({ theme }) => theme.shadow[500]}, inset 3px -3px 6px ${({ theme }) => theme.shadow[500]}, inset -3px -3px 6px ${({ theme }) => theme.shadow[400]}, inset 3px 3px 8px ${({ theme }) => theme.shadow[700]};
        
            .dz-ui-header{
                svg{
                    fill: ${({ theme }) => theme.gray[100]};
                }
            }

            .file-item-list-container{
                .file-item-name{
                    color: ${({ theme }) => theme.gray[100]};
                }
            }

            .dz-ui-footer{
                width: 100%;
                padding-inline-start: 1vw;
            }
        }
    }
`;

export const CategoriesContainer = styled.div`
    gap: max(22px, 3vh);
`;

export const ImagesContainerSm = styled.div`
    width: 35%;
    height: 70vh;
    overflow: hidden;
    overflow-y: auto;
    background: ${({ theme }) => theme.gray[700]};
	box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[300]}, 
	-1px -1px 2px ${({ theme }) => theme.shadow[600]}, 
	inset -3px 3px 6px ${({ theme }) => theme.shadow[500]}, 
	inset -2px -2px 5px ${({ theme }) => theme.shadow[400]}, 
	inset 3px 3px 10px ${({ theme }) => theme.shadow[700]};
    border-radius: 16px;
    padding: 10px;
`;

export const ImagesContainerLg = styled.div`
    width: 70%;
    max-height: 70vh;
    overflow-y: auto;
    gap: 16px;
    background: ${({ theme }) => theme.gray[700]};
	box-shadow: 1px 1px 2px ${({ theme }) => theme.shadow[300]}, 
	-1px -1px 2px ${({ theme }) => theme.shadow[600]}, 
	inset -3px 3px 6px ${({ theme }) => theme.shadow[500]}, 
	inset -2px -2px 5px ${({ theme }) => theme.shadow[400]}, 
	inset 3px 3px 10px ${({ theme }) => theme.shadow[700]};
    border-radius: 16px;
    padding: 10px;

    .grid-item{
        width: 24%;
        cursor: default;
        overflow: hidden;

        img{
            border-radius: 10px;
            height: 100%;
            width:100%;
            object-fit: cover;
            object-position: center;
        }
    }
`;

export const ProjectContainer = styled.div`
    width: 90vw;
    display: flex;
    flex-direction: column;
    gap: 5vh;
    align-items: center;
    justify-content: center;
    // overflow-y: auto;

    .btns{
        button{
            min-width: 7vw;

            &.prev-btn{
                padding-inline-start: 10px;
            }

            &.next-btn{
                padding-inline-end: 10px;
            }
        }
        .icon-wrapper{
            height: 46px;
            aspect-ratio: 1;
            
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 10px;
            }
        }
    }
`;

export const GridItem = styled.div`
    cursor: grab;
    display: flex;
    flex-direction: column;
    gap: 5%;
    padding: 10px;
    border-radius: 10px;
    // min-height: 180px; //it makes a problem with grid library
    background: ${({ theme }) => theme.gray[500]};
    .img-wrapper{
        border-radius: 10px;
        overflow: hidden;
        height: 80%;
        width:100%;
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .text-wrapper{
        height: 15%;
        z-index: 99999999;
    }
`;