import React, { useContext, useEffect, useState } from 'react';
import { CardStyle, FlexBox, FormInputStyle } from '../Styles/GeneralStyles';
import FormDecoration from '../assets/imgs/contact-form-decoration.png';
import AppContext from '../Contexts/AppContext';
import { ReactComponent as ArrowsIcon } from '../assets/imgs/arrows.svg';
import { ReactComponent as DarkArrowsIcon } from '../assets/imgs/arrows-dark.svg';
import { SendContactForm } from '../services/api';
import { alertPopup } from '../services/alertPopup';
import { useNavigate } from 'react-router-dom';

export function ContactForm() {
	const { handleGetFields, globalFields, isDarkTheme } =
		useContext(AppContext);
	const [formData, setFormData] = useState({
		name: '',
		phone: '',
		email: '',
		company: '',
		message: '',
	});
	const navigate = useNavigate();

	useEffect(() => {
		handleGetFields(0);
	}, []);

	const submitForm = async (e) => {
		e.preventDefault();

		const emailRegex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		const phoneRegex = /^0\d{8,9}$/;

		if (formData.name.length < 3) {
			alertPopup('Name must be at least 3 letters', 'error');
			return;
		}

		if (!phoneRegex.test(formData.phone)) {
			alertPopup('Invalid Phone number', 'error');
			return;
		}

		if (!emailRegex.test(formData.email)) {
			alertPopup('Invalid Email', 'error');
			return;
		}

		if (formData.company.length < 2) {
			alertPopup('Company must be at least 2 letters', 'error');
			return;
		}

		const res = await SendContactForm(formData);
		if (res.success) {
			alertPopup('Thank You! Email sent!', 'success');
			navigate('/thank-you', { state: { isFormSubmitted: true } });
		} else alertPopup('Error occured! Try again later', 'error');
	};

	const handleChange = (e) => {
		let tmp_obj = { ...formData };

		tmp_obj[`${e.target.id}`] = e.target.value;
		setFormData(tmp_obj);
	};

	return (
		<CardStyle id={'form'} ContactForm className='flex'>
			<FlexBox
				className='title'
				style={{ backgroundImage: `url(${FormDecoration})` }}
			>
				<h2>{globalFields?.contact_us_title?.field_content}</h2>
			</FlexBox>
			<div className='form'>
				<form onSubmit={submitForm}>
					<FormInputStyle className={`flex-column name`}>
						<label htmlFor={'name'}>Name</label>
						<input
							type={'text'}
							id={'name'}
							required
							onChange={(e) => {
								handleChange(e);
							}}
						/>
					</FormInputStyle>
					<FormInputStyle className={`flex-column phone`}>
						<label htmlFor={'phone'}>Phone</label>
						<input
							type={'tel'}
							id={'phone'}
							required
							onChange={(e) => {
								handleChange(e);
							}}
						/>
					</FormInputStyle>
					<FormInputStyle className={`flex-column email`}>
						<label htmlFor={'email'}>Email</label>
						<input
							type={'text'}
							id={'email'}
							required
							onChange={(e) => {
								handleChange(e);
							}}
						/>
					</FormInputStyle>
					<FormInputStyle className={`flex-column company`}>
						<label htmlFor={'company'}>Company</label>
						<input
							type={'text'}
							id={'company'}
							required
							onChange={(e) => {
								handleChange(e);
							}}
						/>
					</FormInputStyle>
					<FormInputStyle className={`flex-column message`}>
						<label htmlFor={'message'}>Message</label>
						<textarea
							name={'message'}
							id={'message'}
							cols='30'
							rows='5'
							onChange={(e) => {
								handleChange(e);
							}}
						></textarea>
					</FormInputStyle>
					<div className='submit-wrapper'>
						<button
							type='submit'
							className='flex justify-center align-center'
						>
							<span>Send</span>
							<span className='arrows relative'>
								{' '}
								{isDarkTheme ? (
									<ArrowsIcon />
								) : (
									<DarkArrowsIcon />
								)}{' '}
							</span>
						</button>
					</div>
				</form>
			</div>
		</CardStyle>
	);
}

export default ContactForm;
